import "react-phone-input-2/lib/style.css";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import Auth from "../../layout/Auth";
import Input from "../../common/Input";
import { ISignupData } from "../../types/auth";
import Button from "../../common/Button";
import Select from "../../common/Select";
import { countries, ROLES } from "../../utils/constant";
import Textarea from "../../common/Textarea";
import Api from "../../api/api";
import { isMobile } from "react-device-detect";

const initialData = {
  fname: "",
  lname: "",
  email: "",
  country: "",
  phone: "",
  about: "",
  password: "",
  confirmPassword: "",
};

const Signup: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState<ISignupData>({ ...initialData });
  const fetchUserLocation = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const locationData = await response.json();
      const countryCode = locationData?.country || "IL";
      setData(prev => ({ ...prev, country: countryCode }));
    } catch (error) {
      console.error("Failed to fetch user location:", error);
      setData(prev => ({ ...prev, country: "IL" }));
    }
  };
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };
  const handleGoogleSignIn = (response: any) => {
    const idToken = response.credential;
    const accessToken = response.accessToken;

    (window as any).googleToken = idToken;

    handleSubmitGoogleLogin(idToken);
  };
  const handleSubmitGoogleLogin = async (googleToken: string) => {
    try {
      const res = await Api.login({ googleToken: googleToken });
      if (res.status === 200) {
        const user = res?.data?.user;

        if (ROLES.ADMIN === user?.role) {
          window.location.replace("/admin/dashboard");
        } else {
          window.location.href = `/seller/${user._id}`;
        }
      }
    } catch (error) {
      console.error("Google login failed:", error);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      return toast.error("Password doesn't match");
    }

    setIsDisable(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { confirmPassword, ..._data } = data;
      const res = await Api.signup({
        ..._data,
        isNew: false,
        type: type || "seller",
      });
      if (res.status === 200) {
        history.replace("/auth/login");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);
  useEffect(() => {
    (window as any).google?.accounts?.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleGoogleSignIn,
      ux_mode: "popup",
      response_type: "token id_token",
      scope:
        "profile email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.addresses.read",
    });
    (window as any).google?.accounts?.id.renderButton(
      document.getElementById("googleSignInBtn"),
      {
        theme: "outline",
        size: "large",
        width: isMobile ? "270" : "400",
        text: "continue_with",
      },
    );
  }, []);
  useEffect(() => {
    fetchUserLocation();
  }, []);
  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);
  return (
    <Auth
      title="Create an Account"
      submitHandle={handleSubmit}
    >
      <div className="max-w-full mt-[-1rem]">
        {/* Flexbox for name fields */}
        <div className="flex flex-col sm:flex-row sm:gap-4 sm:items-center mt-4">
          <Input
            className="flex-1 mb-1 sm:mt-[0.3rem] sm:mb-0"
            title="First Name*"
            name="fname"
            value={data?.fname}
            onChange={handleInputChange}
            placeholder="Enter First Name"
            required
          />
          <Input
            className="flex-1 sm:mt-[0.2rem] sm:mb-0"
            title="Last Name*"
            name="lname"
            value={data?.lname}
            onChange={handleInputChange}
            placeholder="Enter Last Name"
            required
          />
        </div>

        <Input
          type="email"
          title="Email*"
          name="email"
          value={data?.email}
          onChange={handleInputChange}
          placeholder="Enter Email"
          required
          className="!mt-0.5"
        />

        <Select
          title="Country*"
          name="country"
          value={data?.country}
          onChange={handleInputChange}
          required
          options={countries}
          className="!mt-0.5"
        />

        <div className="relative ">
          <label className="capitalize block mb-1 text-secondary text-[15px]">
            Phone Number
          </label>
          <PhoneInput
            country={data?.country?.toLowerCase()}
            containerClass="bg-[#fff] border border-[#A6A9ACB2] rounded-sm text-sm w-full"
            inputClass="!h-[45px] !w-full !bg-[#fff] !border-none !rounded-sm !pl-10"
            buttonClass="!bg-[#fff] !border-none !rounded-sm"
            onChange={(value: string) =>
              setData(prev => ({ ...prev, phone: value }))
            }
            value={data.phone}
          />
        </div>

        <Input
          type="password"
          title="Password*"
          name="password"
          value={data?.password}
          onChange={handleInputChange}
          placeholder="Enter Password"
          required
          className="!mt-0.5"
        />

        <Input
          type="password"
          title="Confirm Password*"
          name="confirmPassword"
          value={data?.confirmPassword}
          onChange={handleInputChange}
          placeholder="Confirm Password"
          required
          className="!mt-0.5"
        />
        <div className="flex whitespace-nowrap text-xs flex gap-1 items-center justify-center text-secondary mt-4">
          By Signing up, you agree to our{" "}
          <Link
            to="/disclaimer"
            className="text-primary font-medium"
          >
            Terms of Service
          </Link>
          {" & "}
          <Link
            to="/privacy-policy"
            className="text-primary font-medium"
          >
            Privacy Policy
          </Link>
        </div>
        <Button
          className="mt-3 mb-2 justify-center flex items-center max-w-[400px] sm:max-w-[270px] md:max-w-[400px] mx-auto rounded-sm"
          type="submit"
          disabled={isDisable}
        >
          Signup
        </Button>
        <div className="flex items-center justify-center ">
          <div
            id="googleSignInBtn"
            className="mb-1 items-center justify-center"
          ></div>
        </div>
        <div className="text-sm flex gap-1 items-center justify-center text-secondary mt-4">
          Already a member?
          <Link
            to="/auth/login"
            className="text-primary font-medium"
          >
            Log in
          </Link>
        </div>

        <div className="mt-4 text-center">
 
 <Link to="/" className="text-sm font-medium text-primary">

   Go back to home page
 </Link>
</div>
      </div>
    </Auth>
  );
};

export default Signup;
