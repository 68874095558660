import React, { useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Loader from "../../common/Loader";
import Api from "../../api/api";

const Verify = () => {
  const { token } = useParams<{ token?: string }>();
  const history = useHistory();

  const verifyAccount = useCallback(async () => {
    try {
      await Api.verifyAccount(token);
      history.replace("/auth/login");
    } catch (error) {}
  }, []);

  useEffect(() => {
    verifyAccount();
  }, [verifyAccount]);

  return (
    <div className="h-screen w-screen grid place-items-center">
      <Loader />
    </div>
  );
};

export default Verify;

