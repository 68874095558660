import React from "react";
import { Redirect, Route } from "react-router-dom";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME, ROLES } from "./utils/constant";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { IPublicProps } from "./protectedRoute";

export default function AdminProtected({
  component: Cmp,
  ...rest
}: IPublicProps) {
  const token = Cookies.get(REACT_APP_KEY_NAME);
  const decode_token: JwtPayload & { role?: number } = token
    ? jwtDecode(token)
    : {};

  return (
    <Route
      {...rest}
      exact
      render={props =>
        Cookies.get(REACT_APP_KEY_NAME) !== undefined &&
        localStorage.getItem(REACT_APP_KEY_NAME) ? (
          ROLES.ADMIN === decode_token?.role ? (
            <Cmp {...props} />
          ) : (
            <Redirect to="/dashboard" />
          )
        ) : (
          <>
            {Cookies.remove(REACT_APP_KEY_NAME)}
            <Redirect to="/auth/login" />
          </>
        )
      }
    />
  );
}

