import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";
import logo from "../assets/logo.svg";
import { ADMIN_LINKS, REACT_APP_KEY_NAME } from "../../utils/constant";
import Sidebar from "../../common/Sidebar";
import { useAuth } from "../../context/auth";
import Cookies from "js-cookie";

const AdminHeader: React.FC = () => {
  const location = useLocation();
  const { isAdmin, user } = useAuth();
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const activeLink = (path: string) => {
    if (location.pathname === path) return "text-primary font-medium";
    return "text-main";
  };

  const handleLogout = () => {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
    window.location.reload();
  };

  useEffect(() => {
    setToggleSidebar(false);
    // @ts-expect-error ts-ignore
    document.querySelector("body").style.overflowY = "scroll";
  }, [location.pathname]);
  if (!isAdmin) return null;
  return (
    <header className="bg-white">
      <div className="max-w-[1367px] mx-auto px-4 xl:px-6 w-full flex gap-3 justify-between items-center py-1 md:py-2 relative z-20">
        <div className="flex items-center gap-3 max-lg:hidden">
          <div>
            {ADMIN_LINKS?.map((itm, idx) => (
              <Link
                key={idx}
                to={itm.path}
                className={`max-sm:hidden parent-link relative text-base 2xl:text-xl hover:text-primary p-4 hover:transition ${activeLink(itm.path)}`}
              >
                {itm.title}
              </Link>
            ))}
          </div>
        </div>

        <div className="flex gap-4 max-md:hidden">
          {!user ? (
            <>
              <Link
                to="/auth/login"
                className="rounded-lg border border-primary text-primary bg-[#ffffff] text-base text-center px-4 py-2"
              >
                Log in
              </Link>
              <Link
                to="/auth/signup"
                className="rounded-lg border border-primary bg-primary text-[#ffffff] text-base text-center px-4 py-2"
              >
                Sign up
              </Link>
            </>
          ) : (
            <>
              <div
                onClick={handleLogout}
                className="cursor-pointer rounded-lg border border-primary text-primary bg-[#ffffff] text-base text-center px-4 py-2"
              >
                Log out
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
