import React from "react";
import {
  FaUsers,
  FaHandshake,
  FaEnvelope,
  FaListAlt,
  FaDollarSign,
  FaMobileAlt,
} from "react-icons/fa";

import Footer from "../common/Footer";
import Header from "../common/Header";

const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="min-h-screen max-w-[1560px] mx-auto bg-gray-100 py-10">
        <div className="px-4">
          <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">
            Welcome to Numisnest!
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div>
              <section className="mb-8">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">
                  Who We Are
                </h2>
                <p className="text-gray-600 leading-relaxed text-lg">
                  At Numisnest, we&apos;re more than just a marketplace;
                  we&apos;re a vibrant community built by collectors for
                  collectors. Our platform allows enthusiasts of banknotes,
                  coins, stamps, and various collectibles to connect, trade, and
                  share their passions beyond traditional marketplace
                  constraints.
                </p>
              </section>

              <section className="mb-8">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">
                  Our Mission
                </h2>
                <p className="text-gray-600 leading-relaxed text-lg">
                  We aim to democratize the collecting experience by offering a
                  space that values each collector&apos;s unique interests. For
                  us, collecting is about the stories, history, and connections
                  each item represents.
                </p>
              </section>
            </div>

            <div className="bg-white sm:p-4 md:p-8 shadow-md rounded-lg">
              <h2 className="text-3xl font-semibold text-gray-700 mb-4">
                Why Choose Numisnest?
              </h2>
              <ul className="space-y-6">
                <li className="flex items-center">
                  <FaListAlt className="text-primary sm:text-7xl md:text-6xl mr-4" />
                  <p className="text-gray-600 leading-relaxed text-lg">
                    <strong>Diverse Collectibles:</strong> Explore a wide array
                    of collectibles from rare banknotes to nostalgic tokens
                    across multiple categories.
                  </p>
                </li>
                <li className="flex items-center">
                  <FaHandshake className="text-primary sm:text-7xl md:text-6xl mr-4" />
                  <p className="text-gray-600 leading-relaxed text-lg">
                    <strong>Direct Interaction:</strong> Engage directly with
                    sellers, discuss items in-depth, and make each transaction
                    personal.
                  </p>
                </li>
                <li className="flex items-center">
                  <FaUsers className="text-primary sm:text-5xl md:text-4xl mr-4" />
                  <p className="text-gray-600 leading-relaxed text-lg">
                    <strong>Curated Collections:</strong> Explore or build
                    collections that reflect your collecting journey.
                  </p>
                </li>
                <li className="flex items-center">
                  <FaMobileAlt className="text-primary sm:text-5xl md:text-3xl mr-4" />
                  <p className="text-gray-600 leading-relaxed text-lg">
                    <strong>User-Friendly Experience:</strong> Our intuitive
                    platform enhances your browsing experience on both desktop
                    and mobile.
                  </p>
                </li>
                <li className="flex items-center">
                  <FaDollarSign className="text-primary sm:text-4xl md:text-3xl mr-4" />
                  <p className="text-gray-600 leading-relaxed text-lg">
                    <strong>No Fees, Full Flexibility:</strong> Sell your items
                    on your terms without worrying about fees or rigid policies.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="text-center mt-16">
            <h2 className="text-4xl font-bold text-gray-700 mb-6">
              Contact Us
            </h2>
            <p className="text-gray-600 justify-center text-center sm:justify-left md:justify-center sm:text-left md:text-center leading-relaxed text-lg max-w-3xl mx-auto mb-8">
              <p className="text-lg leading-relaxed max-w-xl mx-auto mb-6">
                Have questions or need assistance? We&apos;re here to help!
                Reach out through our contact page or directly via email.
              </p>
            </p>
            <div className="flex justify-center items-center space-x-8">
              <FaEnvelope className="text-white text-4xl" />
              <a
                href="mailto:contact.us@numisnest.com"
                className="text-lg text-black hover:underline"
              >
                contact.us@numisnest.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
