import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div className="grid place-items-center p-3 border-t border-[#69696980]">
      <div className="mb-2 text-primary text-lg underline font-semibold">
        <Link to="/disclaimer">Disclaimer</Link>{" "}
        <Link
          className="ml-3"
          to="/privacy-policy"
        >
          Privacy Policy
        </Link>
      </div>

      <p className="text-sm text-center">
        Copyright© 2024 Numisnest .All Rights Reserved.
      </p>
    </div>
  );
};

export default Footer;

