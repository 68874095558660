import React, { useEffect, useState } from "react";
import Footer from "../../common/Footer";
import Collection from "../../components/Collection";
import Api from "../../api/api";
import { useAuth } from "../../context/auth";
import Button from "../../common/Button";
import Modal from "react-modal";
import avatar from "../../assets/avatar.jpg";
import { FaPlusCircle, FaSpinner } from "react-icons/fa";
import AdminHeader from "../../components/Admin/AdminHeader";
import toast from "react-hot-toast";
import ConfirmationModal from "../../components/ConfirmationModal";

Modal.setAppElement("#root");

const AdminCollectionsPage = () => {
  const { user } = useAuth(); // Use for authentication if needed
  const [collections, setCollections] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");
  const [modalError, setModalError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [selectedCollections, setSelectedCollections] = useState<string[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [bulkOperation, setBulkOperation] = useState(false);
  const [actionToConfirm, setActionToConfirm] = useState<string>("");
  const [modalLoading, setModalLoading] = useState(false);
  // Fetch all collections for the admin
  const fetchCollections = async () => {
    const res: any = await Api.getCollectionAdmin();

    if (res.status === 200) {
      setCollections(res?.data?.data);
    }
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearchQuery(e.target.value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setSearchQuery(tempSearchQuery); // Trigger search when 'Enter' is pressed
    }
  };
  const handleCollectionUpdate = updatedCollection => {
    fetchCollections();
  };
  const handleAddCollection = async () => {
    if (newCollectionName.trim() === "") {
      setModalError("Collection name is required.");
      return;
    }

    setIsLoading(true);
    setModalError("");

    try {
      const res = await Api.addCollection({ name: newCollectionName });
      if (res.status === 201) {
        setIsLoading(false);
        setIsModalOpen(false);
        fetchCollections(); // Refresh the collections after adding
      }
    } catch (err) {
      console.error("Failed to create collection", err);
      setModalError("Failed to create collection. Please try again.");
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setNewCollectionName("");
    setModalError("");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const filteredCollections = collections.filter(collection =>
    collection.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const handleSelectItem = (itemId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedCollections(prev => [...prev, itemId]); // Add item to the selection
    } else {
      setSelectedCollections(prev => prev.filter(id => id !== itemId)); // Remove item from selection
    }
  };

  const handleBulkAction = (action: string) => {
    if (selectedCollections.length === 0) {
      toast.error("No items selected");
      return;
    }
    setActionToConfirm(action);
    setShowConfirmationModal(true);
  };

  const handleConfirmAction = async () => {
    setModalLoading(true);
    try {
      switch (actionToConfirm) {
        case "delete":
          await Api.bulkDeleteCollections({
            collectionIds: selectedCollections,
          });
          toast.success("Collections deleted successfully");
          setSelectedCollections([]);
          break;
        case "hide":
          const res = await Api.bulkHideCollections({
            collectionIds: selectedCollections,
          });
          if (res.status === 200) {
            toast.success("Collections hidden successfully");

            setSelectedCollections([]);
          }
          break;

        default:
          break;
      }
      await fetchCollections();
    } catch (error) {
      toast.error("Action failed");
    } finally {
      setModalLoading(false);
      setShowConfirmationModal(false);
    }
  };

  return (
    <>
      <AdminHeader />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div className="text-center mb-8">
              <h1 className="text-3xl font-bold">Collections</h1>
            </div>
            <div className="flex flex-wrap flex-col sm:flex-row justify-between items-center mb-8 gap-4">
              {/* Avatar Section */}

              {/* Search Bar */}
              <div className="flex-grow text-center w-full sm:w-auto">
                <input
                  type="text"
                  value={tempSearchQuery}
                  onChange={handleSearchChange}
                  onKeyDown={handleSearchKeyPress}
                  placeholder="Search collections..."
                  className="w-full max-w-[250px] p-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="flex items-center gap-4 mb-6">
              <span>{selectedCollections.length} items selected</span>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={() => handleBulkAction("delete")}
              >
                Delete Selected
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded"
                onClick={() => handleBulkAction("hide")}
              >
                (Un)hide Selected
              </button>
            </div>
            {/* Collections List */}
            <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {filteredCollections.map(collection => (
                <Collection
                  key={collection._id}
                  collection={collection}
                  isSeller={true} // Always true for admin
                  isSelected={selectedCollections.includes(collection._id)}
                  onSelectItem={handleSelectItem}
                  onCollectionUpdate={handleCollectionUpdate}
                />
              ))}
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirmAction}
        message={`Are you sure you want to ${actionToConfirm} the selected items?`}
        confirmButtonLabel={actionToConfirm === "delete" ? "Delete" : "Confirm"}
        loading={modalLoading}
      />
      {/* Modal for adding a new collection */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Add New Collection"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">Add New Collection</h2>
        <input
          type="text"
          value={newCollectionName}
          onChange={e => setNewCollectionName(e.target.value)}
          placeholder="Enter collection title"
          className="w-full p-2 border border-gray-300 rounded-md mb-4"
        />
        {modalError && <p className="text-red-500 mb-4">{modalError}</p>}
        <div className="flex justify-end gap-4">
          <Button
            onClick={handleCloseModal}
            className="px-4 py-2 bg-gray-200"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddCollection}
            className="px-4 py-2 bg-blue-500 text-white"
            disabled={isLoading}
          >
            {isLoading ? <FaSpinner className="animate-spin mr-2" /> : "Create"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AdminCollectionsPage;
