import axios from "axios";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME } from "../utils/constant";
import toast from "react-hot-toast";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { jsonToQueryParams } from "../helpers/functions";

// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const getJwt = () => {
  if (
    Cookies.get(REACT_APP_KEY_NAME) !== undefined &&
    localStorage.getItem(REACT_APP_KEY_NAME)
  ) {
    return Cookies.get(REACT_APP_KEY_NAME);
  } else {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
  }
};

const setJwt = (key: string) => {
  Cookies.set(REACT_APP_KEY_NAME, key, {
    expires: 1,
    path: "/",
    secure: false,
  });
  localStorage.setItem(REACT_APP_KEY_NAME, key);
};

const setAuthHeader = (token?: string) => {
  if (!token) return;
  axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
  setJwt(token);
};

if (getJwt()) {
  setAuthHeader(getJwt());
}

export default class Api {
  //login
  static login = async data => {
    try {
      const res = await axios.post(`/api/v1/auth/login`, data);
      setAuthHeader(res.data?.token);
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      localStorage.removeItem(REACT_APP_KEY_NAME);
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static contactUs = async data => {
    try {
      const res = await axios.post(`/api/v1/contact/send`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  //signup
  static signup = async data => {
    try {
      const res = await axios.post(`/api/v1/auth/signup`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //get me profile
  static getMeProfile = async () => {
    try {
      const res = await axios.get(`/api/v1/auth/me`);
      return res;
    } catch (error: any) {
      Cookies.remove(REACT_APP_KEY_NAME);
      localStorage.removeItem(REACT_APP_KEY_NAME);
      window.location.href = "/";
      return error.response.data;
    }
  };

  //forgot password
  static forgotPassword = async data => {
    try {
      const res = await axios.post(`/api/v1/auth/password/forgot`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //reset password
  static resetPassword = async data => {
    try {
      const res = await axios.post(`/api/v1/auth/password/reset`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //change password
  static changePassword = async data => {
    try {
      const res = await axios.put(`/api/v1/auth/password/change`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static updateVisibilitySettings = async visibilitySettings => {
    try {
      const response = await axios.post(
        "/api/v1/auth/visibility-settings/update",
        visibilitySettings,
      );
      return response;
    } catch (error) {
      console.error("Error updating visibility settings:", error);
      throw error;
    }
  };
  //verify account
  static verifyAccount = async token => {
    try {
      const res = await axios.post(`/api/v1/auth/email/verify/${token}`);
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static getUserCountries = async () => {
    try {
      const res = await axios.get(`/api/v1/auth/user/countries`);

      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  //update my profile
  static updateMyProfile = async data => {
    try {
      const res = await axios.put(`/api/v1/auth/user/update`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static updateProfileByAdmin = async data => {
    try {
      const res = await axios.put(`/api/v1/admin/user/update/${data.id}`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static updateAvatarByAdmin = async (id, imageData) => {
    try {
      const res = await axios.put(`/api/v1/admin/user/update-avatar/${id}`, {
        image: imageData,
      });
      toast.success("Avatar updated successfully!");
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data?.message || "Error updating avatar");
      return null;
    }
  };
  static getUsersByAdmin = async (type, search = "", page = 1, limit = 10) => {
    try {
      const res = await axios.get(
        `/api/v1/admin/user/all?type=${type}&search=${search}&page=${page}&limit=${limit}`,
      );
      return res;
    } catch (error: any) {
      return error.response.data;
    }
  };
  static bulkUpdateCategories = async (data: { categories: any[] }) => {
    try {
      const response = await axios.post(
        "/api/v1/admin/categories/bulk/update",
        data,
      );
      return response;
    } catch (error: any) {
      throw new Error(`Failed to update categories: ${error.message}`);
    }
  };

  static updateItem = async data => {
    try {
      const res = await axios.put(`/api/v1/item/update`, data);

      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static updateItemAdmin = async data => {
    try {
      const res = await axios.put(`/api/v1/admin/item/update`, data);

      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static bulkUpdateItem = async itemIds => {
    try {
      const res = await axios.put(`/api/v1/admin/item/bulk/update`, itemIds);

      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static bulkRestoreItem = async itemIds => {
    try {
      const res = await axios.put(`/api/v1/admin/item/bulk/restore`, itemIds);

      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  //get user
  static getUser = async id => {
    try {
      const res = await axios.get(`/api/v1/auth/id/${id}`);
      return res;
    } catch (error: any) {
      return error.response.data;
    }
  };

  //get user with type and pagination
  static getUsers = async (type, search = "", page = 1, limit = 10) => {
    try {
      const res = await axios.get(
        `/api/v1/auth/all?type=${type}&search=${search}&page=${page}&limit=${limit}`,
      );
      return res;
    } catch (error: any) {
      return error.response.data;
    }
  };
  static searchItems = async (searchQuery: string) => {
    try {
      const encodedQuery = encodeURIComponent(searchQuery);

      const res = await axios.get(`/api/v1/item/search?search=${encodedQuery}`);

      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static searchUserItems = async (id, searchQuery: string) => {
    try {
      const encodedQuery = encodeURIComponent(searchQuery);

      const res = await axios.get(
        `/api/v1/item/user-items/${id}/search?search=${encodedQuery}`,
      );

      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static search = async (searchQuery: string) => {
    try {
      const encodedQuery = encodeURIComponent(searchQuery);

      const res = await axios.get(`/api/v1/search?search=${encodedQuery}`);

      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  static searchSeller = async (searchQuery: string) => {
    try {
      const encodedQuery = encodeURIComponent(searchQuery);

      const res = await axios.get(
        `/api/v1/search/seller?search=${encodedQuery}`,
      );

      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  //get category
  static getCategories = async () => {
    try {
      const res = await axios.get(`/api/v1/category/all/`);
      return res;
    } catch (error: any) {
      return error.response.data;
    }
  };
  static getUsedCategories = async () => {
    try {
      const res = await axios.get(`/api/v1/category/all/used`);
      return res;
    } catch (error: any) {
      return error.response.data;
    }
  };
  //get category by id
  static getCategory = async id => {
    try {
      const res = await axios.get(`/api/v1/category/id/${id}`);
      return res;
    } catch (error: any) {
      return error.response.data;
    }
  };
  static getCollectionAdmin = async () => {
    try {
      const res = await axios.get(`/api/v1/admin/get/collection`);
      return res;
    } catch (error: any) {
      return error.response.data;
    }
  };
  static deleteCategory = async id => {
    try {
      const res = await axios.delete(`/api/v1/category/delete/${id}`);
      return res;
    } catch (error: any) {
      return error.response.data;
    }
  };
  static addCategory = async data => {
    try {
      const res = await axios.post(`/api/v1/category/add`, data);
      return res;
    } catch (error: any) {
      return error.response.data;
    }
  };
  static updateCategory = async data => {
    try {
      const res = await axios.put(`/api/v1/category/update`, data);
      return res;
    } catch (error: any) {
      return error.response.data;
    }
  };
  static getSimilarItems = async id => {
    try {
      const res = await axios.get(`/api/v1/item/similar/${id}`);
      return res;
    } catch (error: any) {
      return error.response.data;
    }
  };
  // add item
  static addItem = async data => {
    try {
      const res = await axios.post(`/api/v1/item/add`, data);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static deleteItem = async id => {
    try {
      const res = await axios.delete(`/api/v1/item/delete/${id}`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static deleteItemAdmin = async id => {
    try {
      const res = await axios.delete(`/api/v1/admin/item/delete/${id}`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static bulkDeleteItem = async itemIds => {
    try {
      const res = await axios.put(`/api/v1/admin/item/bulk/delete`, itemIds);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static bulkDeleteCollections = async data => {
    try {
      const res = await axios.put(`/api/v1/admin/collection/bulk/delete`, data);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static bulkHideItem = async itemIds => {
    try {
      const res = await axios.put(`/api/v1/admin/item/bulk/hide`, itemIds);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static bulkHideCollections = async collectionIds => {
    try {
      const res = await axios.put(
        `/api/v1/admin/collection/bulk/hide`,
        collectionIds,
      );
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static deleteUserByAdmin = async id => {
    try {
      const res = await axios.delete(`/api/v1/admin/delete/user/${id}`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static deleteUser = async id => {
    try {
      const res = await axios.delete(`/api/v1/auth/delete/${id}`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static reorderBlogPosts = async (orderedIds: string[]) => {
    try {
      const res = await axios.post("/api/v1/blog/reorder", { orderedIds });
      toast.success("Blog posts reordered successfully!");
      return res.data;
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || "Error reordering blog posts",
      );
      return error.response?.data;
    }
  };

  static updateBlogPost = async (id, blogPostData) => {
    try {
      const res = await axios.put(`/api/v1/blog/update/${id}`, blogPostData);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static getBlogPost = async id => {
    try {
      const res = await axios.get(`/api/v1/blog/get/${id}`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static getAllBlogPost = async () => {
    try {
      const res = await axios.get(`/api/v1/blog/all`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static createBlogPost = async blogPostData => {
    try {
      const res = await axios.post(`/api/v1/blog/add`, blogPostData);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static deleteBlogPost = async id => {
    try {
      const res = await axios.delete(`/api/v1/blog/delete/${id}`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static toggleVerifyByAdmin = async id => {
    try {
      const res = await axios.post(`/api/v1/admin/verify/user/${id}`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static toggleTopRatedyByAdmin = async id => {
    try {
      const res = await axios.post(`/api/v1/admin/top-rated/user/${id}`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static toggleActivateByAdmin = async id => {
    try {
      const res = await axios.post(`/api/v1/admin/approve/user/${id}`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static getUserItem = async data => {
    try {
      const res = await axios.get(`/api/v1/item/user-items/${data?.id}`, data);
      return res;
    } catch (error: any) {
      // toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static getUserDeletedItem = async data => {
    try {
      const res = await axios.get(`/api/v1/admin/user/item/${data?.id}`, data);
      return res;
    } catch (error: any) {
      // toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static getFeaturedItem = async data => {
    try {
      const res = await axios.get(
        `/api/v1/item/featured-items/${data?.id}`,
        data,
      );
      return res;
    } catch (error: any) {
      //toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static getAllItem = async data => {
    try {
      const res = await axios.get(`/api/v1/item/all/`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static getHomePageItem = async data => {
    try {
      const res = await axios.get(`/api/v1/item/home-page/`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static getAllItemAdmin = async data => {
    try {
      const res = await axios.get(`/api/v1/admin/item/all/`);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static getItemByID = async (id: string) => {
    try {
      const res = await axios.get(`/api/v1/item/id/${id}`);

      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static updateAvatar = async imageData => {
    try {
      const res = await axios.put("/api/v1/auth/user/update-avatar", {
        image: imageData,
      });
      toast.success("Avatar updated successfully!");
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data?.message || "Error updating avatar");
      return null;
    }
  };

  static getCollection = async params => {
    try {
      const res = await axios.get(
        `/api/v1/collection/get/${params.collectionId}/${params.userId}`,
      );
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  static getSingleCollectionAdmin = async params => {
    try {
      const res = await axios.get(
        `/api/v1/collection/get/${params.collectionId}/${params.userId}`,
      );
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static getAllCollection = async userId => {
    try {
      const res = await axios.get(`/api/v1/collection/all/${userId}`);

      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static deleteImage = async publicId => {
    try {
      const res = await axios.delete(`/api/v1/item/delete-image/${publicId}`);

      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static addCollection = async data => {
    try {
      const res = await axios.post(`/api/v1/collection/add`, data);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static deleteCollection = async collectionId => {
    try {
      const res = await axios.delete(
        `/api/v1/collection/delete/${collectionId}`,
      );

      toast.success("Collection deleted successfully!");
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static deleteCollectionAdmin = async collectionId => {
    try {
      const res = await axios.delete(
        `/api/v1/admin/collection/delete/${collectionId}`,
      );

      toast.success("Collection deleted successfully!");
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static updateCollectionPosition = async data => {
    try {
      const res = await axios.put(`/api/v1/collection/update/position`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static updateCollection = async data => {
    try {
      const res = await axios.put(
        `/api/v1/collection/update/${data.collectionId}`,
        data,
      );
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static updateCollectionAdmin = async data => {
    try {
      const res = await axios.put(
        `/api/v1/admin/collection/update/${data.collectionId}`,
        data,
      );
      toast.success(res?.data?.message);
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static searchCollection = async data => {
    try {
      const res = await axios.post(
        `/api/v1/collection/search/${data.userId}`,
        data,
      );
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
  static searchItemInCollection = async data => {
    try {
      const res = await axios.post(
        `/api/v1/collection/search/item/${data.userId}`,
        data,
      );
      return res;
    } catch (error: any) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  static getConversations = async () => {
    try {
      const res = await axios.get(`/api/v1/conversations`);
      return res;
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || "Error fetching conversations",
      );
      return error.response?.data;
    }
  };
  static getConversationAdmin = async id => {
    try {
      const res = await axios.get(`/api/v1/admin/conversation/${id}`);
      return res;
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || "Error fetching conversations",
      );
      return error.response?.data;
    }
  };
  static getStatistics = async () => {
    try {
      const res = await axios.get(`/api/v1/admin/statistics`);
      return res;
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || "Error fetching conversations",
      );
      return error.response?.data;
    }
  };
  static getMessages = async params => {
    try {
      const res = await axios.get(`/api/v1/message/${params.conversationId}`);
      return res;
    } catch (error: any) {
      toast.error(error.response?.data?.message || "Error fetching messages");
      return error.response?.data;
    }
  };
  static sendMessage = async params => {
    try {
      const res = await axios.post(`/api/v1/message`, params);
      return res;
    } catch (error: any) {
      toast.error(error.response?.data?.message || "Error sending message");
      return error.response?.data;
    }
  };
  static deleteMessage = async params => {
    try {
      const res = await axios.delete(`/api/v1/message/${params.messageId}`);
      return res;
    } catch (error: any) {
      toast.error(error.response?.data?.message || "Error deleting message");
      return error.response?.data;
    }
  };
  static createConversation = async params => {
    try {
      const res = await axios.post(`/api/v1/conversations`, {
        participantIds: params.participantIds, // Array of user IDs
      });
      return res;
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || "Error creating conversation",
      );
      return error.response?.data;
    }
  };
  static deleteConversation = async params => {
    try {
      const res = await axios.delete(
        `/api/v1/conversations/${params.conversationId}`,
      );
      return res;
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || "Error deleting conversation",
      );
      return error.response?.data;
    }
  };
  static getFavorites = async params => {
    try {
      const res = await axios.get(`/api/v1/item/favorite/get`, params);
      return res;
    } catch (error: any) {
      toast.error(error.response?.data?.message || "Error fetching favorites");
      return error.response?.data;
    }
  };
  static removeFromFavorites = async params => {
    try {
      const res = await axios.delete(
        `/api/v1/item/favorite/${params.id}`,
        params,
      );
      return res;
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || "Error deleting from favorites",
      );
      return error.response?.data;
    }
  };
  static addToFavorites = async params => {
    try {
      const res = await axios.post(
        `/api/v1/item/favorite/${params.id}`,
        params,
      );
      return res;
    } catch (error: any) {
      toast.error(error.response?.data?.message || "Error adding to favorites");
      return error.response?.data;
    }
  };
}
