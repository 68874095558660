import React, { useEffect, useRef, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Api from "../../api/api";
import { useAuth } from "../../context/auth";
import avatar from "../../assets/avatar.jpg";
import { FaTrash, FaCross } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";

import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat"; // For using abbreviated month format

// Extend dayjs with the necessary plugins
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

interface Message {
  _id: string;
  text: string;
  sender: { _id: string; fname: string; lname: string; avatar?: string };
  receiver: { _id: string; fname: string; lname: string };
  createdAt: string;
  itemId?: string;
}
interface Conversation {
  _id: string;
  participants: {
    _id: string;
    fname: string;
    lname: string;
    avatar?: string;
  }[];
  lastMessage: string;
  updatedAt: string;
}
const MessagesPage: React.FC = () => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user: isUser } = useAuth();
  const [items, setItems] = useState<{ [key: string]: any }>({});
  useEffect(() => {
    fetchConversations();
  }, []);
  useEffect(() => {
    fetchMessages();
  }, [selectedConversation]);
  const fetchConversations = async () => {
    setIsLoading(true);
    try {
      const res = await Api.getConversations();
      if (res.status === 200 && Array.isArray(res.data.data)) {
        const sortedConversations = [...res.data.data].sort((a, b) => {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        });
        setConversations(sortedConversations);
        if (!selectedConversation && sortedConversations.length > 0) {
          setSelectedConversation(sortedConversations[0]);
        }
      } else {
        setConversations([]);
      }
    } catch (error) {
      console.error("Error fetching conversations:", error);
      setConversations([]);
    } finally {
      setIsLoading(false);
    }
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const maxHeight = 140;
  const isDeletedUser = selectedConversation?.participants.some(
    participant =>
      participant.fname === "Deleted" && participant.lname === "User",
  );

  // Function to adjust the height of the textarea dynamically
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      // Reset the height to auto so it can shrink or grow
      textareaRef.current.style.height = "auto";

      // Calculate the scrollHeight
      const scrollHeight = textareaRef.current.scrollHeight;

      // Set the height based on the scrollHeight but limit it to the maxHeight
      if (scrollHeight > maxHeight) {
        textareaRef.current.style.height = `${maxHeight}px`; // Limit height
        textareaRef.current.style.overflowY = "auto"; // Enable scroll when max height is reached
      } else {
        textareaRef.current.style.height = `${scrollHeight}px`; // Dynamic height
        textareaRef.current.style.overflowY = "hidden"; // Keep overflow hidden until maxHeight is reached
      }
    }
  };

  useEffect(() => {
    adjustTextareaHeight(); // Adjust textarea height when newMessage changes
  }, [newMessage]);
  const fetchMessages = async (
    conversationId: string = selectedConversation?._id || "",
  ) => {
    try {
      if (!conversationId) return;
      const res = await Api.getMessages({ conversationId });
      if (res.status === 200) {
        const fetchedMessages = res.data.data;
        const itemPromises = fetchedMessages
          .filter((msg: Message) => msg.itemId)
          .map(async (msg: Message) => {
            if (msg.itemId) {
              const itemRes = await Api.getItemByID(msg.itemId);
              return { [msg.itemId]: itemRes.data.data };
            }
            return {};
          });
        const itemsArray = await Promise.all(itemPromises);
        const itemMap = Object.assign({}, ...itemsArray);
        setItems(itemMap);
        setMessages(fetchedMessages);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      setMessages([]);
    }
  };
  const isToday = (dateString: string) => {
    return dayjs(dateString).isSame(dayjs(), "day");
  };

  // Function to check if the date is from the same year
  const isThisYear = (dateString: string) => {
    return dayjs(dateString).isSame(dayjs(), "year");
  };

  // Function to format the date or time
  const formatDateOrTime = (dateString: string) => {
    const date = dayjs(dateString);
    const currentDate = dayjs();

    // If it's today, show the time
    if (isToday(dateString)) {
      return date.format("h:mm A");
    }

    // If it's within this year, show month and day (e.g., Oct 17)
    if (isThisYear(dateString)) {
      return date.format("MMM D"); // Abbreviated month and day (e.g., "Oct 17")
    }

    // If it's more than a year ago, show the difference in years (e.g., "1y", "2y")
    return currentDate.diff(date, "year") + "y";
  };

  const handleDeleteMessage = async (messageId: string) => {
    try {
      const res = await Api.deleteMessage({ messageId: messageId });
      if (res.status === 200) {
        setMessages(messages.filter(message => message._id !== messageId));
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };
  const handleSendMessage = async () => {
    if (newMessage.trim() && selectedConversation) {
      const receiver = selectedConversation.participants.find(
        participant => participant._id !== isUser?._id,
      );
      if (!receiver) {
        console.error("Receiver not found in the conversation");
        return;
      }
      try {
        const res = await Api.sendMessage({
          receiverId: receiver._id,
          text: newMessage,
        });
        if (res.status === 200 || res.status === 201) {
          setNewMessage("");
          fetchMessages(selectedConversation._id);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };
  const handleConversationClick = (conversation: Conversation) => {
    setSelectedConversation(conversation);
  };
  return (
    <>
      <Header />
      <div className="min-h-screen flex bg-gray-100">
        {}
        <div className="w-1/3 bg-white shadow-md border-r border-gray-200  ">
          <div className="p-1">
            <h2 className="sm:text-sm sm:ml-[-0.2rem] lg:p-4 md:text-lg font-semibold mb-4">
              Conversations
            </h2>
            {isLoading ? (
              <p>Loading conversations...</p>
            ) : (
              conversations.map(conversation => (
                <div
                  key={conversation._id}
                  className={`p-4 mb-2 sm:p-3 md:p-4 relative cursor-pointer border-b border-gray-200 ${
                    selectedConversation?._id === conversation._id
                      ? "bg-blue-100"
                      : "bg-white"
                  } hover:bg-blue-50`}
                  onClick={() => handleConversationClick(conversation)}
                >
                  {conversation.participants
                    .filter(participant => participant._id !== isUser?._id)
                    .map(participant => (
                      <div
                        key={participant._id}
                        className="flex items-center  sm:gap-1 md:gap-3"
                      >
                        <img
                          src={participant.avatar || avatar}
                          alt="avatar"
                          className="sm:w-7 sm:h-7  ml-[-0.6rem]  md:w-8 md:h-8 rounded-full object-cover"
                        />
                        <p className="font-bold ml-[-0.2rem] mr-[-0.5rem] sm:text-xs md:text-lg text-gray-800 line-clamp-2">
                          {`${participant.fname} ${participant.lname}`}
                        </p>
                        <p className="text-sm text-gray-500 max-md:text-xs absolute bottom-1 right-1 truncate">
                          {formatDateOrTime(conversation.updatedAt)}
                        </p>
                      </div>
                    ))}
                  <p className="text-sm text-gray-500 truncate">
                    {conversation.lastMessage}
                  </p>
                </div>
              ))
            )}
          </div>
        </div>
        {}
        <div className="w-2/3 p-4 sm:p-1 md:pb-0 md:pr-4 md:pl-4 md:pt-4 flex flex-col scrollable">
          {selectedConversation ? (
            <>
              <div className="flex-1 overflow-y-auto mb-4 max-h-[80vh]">
                {messages.length > 0 ? (
                  messages.map(message => (
                    <div
                      key={message._id}
                      className={`p-3 mb-3 sm:p-1 md:p-2 max-w-lg relative ${
                        message.sender._id === isUser?._id
                          ? "bg-blue-500 text-white text-right self-end"
                          : "bg-gray-200 text-left self-start"
                      } rounded-lg`}
                    >
                      <div className="flex items-center gap-2">
                        <img
                          src={message.sender.avatar || avatar}
                          alt="avatar"
                          className="w-8 h-8 rounded-full"
                        />
                        <p className="sm:text-sm sm:mr-1 md:text-base">
                          {message.text}
                        </p>
                      </div>
                      {}
                      {message.itemId && items[message.itemId] && (
                        <div className="bg-gray-100 p-3 mt-3 rounded-lg">
                          <h3 className="font-semibold">
                            {items[message.itemId].name}
                          </h3>
                          <img
                            src={
                              items[message.itemId].photos?.[0]?.url ||
                              "https://via.placeholder.com/150"
                            }
                            alt={items[message.itemId].name}
                            className="w-32 h-32 object-cover mt-2"
                          />
                          <p>{items[message.itemId].description}</p>
                        </div>
                      )}
                      <p className="text-xs top-0 left-0 mt-1 text-gray-1000">
                        {new Date(message.createdAt).toLocaleString()}
                      </p>

                      {/*message.sender._id === isUser?._id && (
                        <button
                          onClick={() => handleDeleteMessage(message._id)}
                          className="absolute top-0 right-0 mt-1 mr-1 text-red-600"
                        >
                          <MdCancel />
                        </button>
                      )*/}
                    </div>
                  ))
                ) : (
                  <p>No messages in this conversation.</p>
                )}
              </div>
              {!isDeletedUser && (
                <div className="p-1 border-t border-gray-300">
                  <div className="flex items-end">
                    <textarea
                      ref={textareaRef}
                      value={newMessage}
                      onChange={e => setNewMessage(e.target.value)}
                      className="flex-1 sm:max-w-[70%] lg:max-w-[40%] border border-gray-300 p-2 rounded-l-lg resize-none overflow-hidden"
                      placeholder="Type a message..."
                      rows={1}
                      style={{
                        maxHeight: isMobile ? "100px" : "140px",
                        minHeight: "40px",
                      }}
                    />
                    <button
                      onClick={handleSendMessage}
                      className="bg-blue-500 text-white px-4 ml-2 rounded-lg h-[40px]"
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <p>Select a conversation to view messages.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default MessagesPage;
