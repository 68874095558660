import React from "react";
import { IInputProps } from "../types/common";

const Input: React.FC<IInputProps> = ({
  title,
  children,
  className,
  ...rest
}) => {
  return (
    <div className={`relative mt-4 ${className}`}>
      {title && (
        <label className="capitalize block mb-1 text-secondary text-[15px]">
          {title}
        </label>
      )}
      <input
        className="w-full border border-[#A6A9ACB2] rounded-md px-4 py-2.5 text-[15px]"
        {...rest}
      />
      {children}
    </div>
  );
};

export default Input;

