import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Search from "../Search";
import { ISelectOption } from "../../types/common";
import { sortedCountries as countries } from "../../utils/constant";
import { IoIosClose } from "react-icons/io";
import Api from "../../api/api";
import { useLocation, useParams } from "react-router-dom";
import { end } from "@cloudinary/url-gen/qualifiers/textAlignment";

const Filters: React.FC<{
  items: any;
  search: string;
  setFilteredItems: React.Dispatch<React.SetStateAction<any[]>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  selectedCategories: any;
  setSelectedCategories: React.Dispatch<any>;
  selectedCountries: any;
  selectedLocations?: any;
  setSelectedCountries: React.Dispatch<any>;
  years: { min: string | number; max: string | number };
  filteredItems?: any;
  setYears: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;
  prices: { min: string | number; max: string | number };
  setPrices: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;
  isSellerItems?: boolean;
  selectedCurrency?: string;
  convertedPrice?: any;
  convertedPrices?: any;
  currency?: any;
  exchangeRates: any;
  setSearchInProgress: any;
  searchInProgress: any;
  setCurrencyChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  currencyChanged?: boolean;
}> = ({
  setFilteredItems,
  search,
  filteredItems,
  setSearch,
  selectedLocations,
  selectedCategories,
  setSelectedCategories,
  selectedCountries,
  setSelectedCountries,
  years,
  setYears,
  prices,
  setPrices,
  isSellerItems = false,
  items,
  convertedPrice,
  convertedPrices,
  currency,
  setSearchInProgress,
  exchangeRates,
  searchInProgress,
  selectedCurrency,
  setCurrencyChanged,
  currencyChanged,
}) => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const hasSeller = q.get("seller");
  const query = q.get("q") || "";
  const id = useParams<any>();
  const [availableCategories, setAvailableCategories] = useState([]);
  const [categories, setCategories] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState(query);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const getUniqueCountriesFromItems = () => {
    const uniqueCountries = Array.from(
      new Set(items.flatMap((item: any) => item.countries)),
    );

    // Filter the full countries list to only include those present in the items
    return countries.filter(country => uniqueCountries.includes(country.value));
  };

  const [filteredCountries, setFilteredCountries] = useState<ISelectOption[]>(
    getUniqueCountriesFromItems(),
  );
  const getAvailableCategories = () => {
    // Get unique categories from filteredItems
    const uniqueCategoryIds = Array.from(
      new Set(
        filteredItems.flatMap(item =>
          item.categories.map(category => category._id),
        ),
      ),
    );

    // Filter the existing category list based on available categories
    const filteredCategories = categories.filter(category =>
      uniqueCategoryIds.includes(category.value),
    );

    // Update the available categories
    setAvailableCategories(filteredCategories);
  };
  async function getAllCategories() {
    const res = await Api.getUsedCategories();
    if (res.status == 200) {
      const temp = res?.data?.data?.map((category: any) => ({
        label: category.name,
        value: category._id,
      }));
      setCategories(
        temp.sort((a: any, b: any) => {
          return a.position - b.position;
        }),
      );
    }
  }
  useEffect(() => {
    getAllCategories();
  }, []);
  useEffect(() => {
    // Update the filtered countries whenever items change
    setFilteredCountries(getUniqueCountriesFromItems());
  }, [items]);

  const handleSearch = async () => {
    if (searchQuery.trim()) {
      setSearchInProgress(true);
      let response;
      if (isSellerItems) {
        response = await Api.searchUserItems(id.id, searchQuery);
      } else {
        response = await Api.searchItems(searchQuery);
      }
      if (response?.status === 200 && Array.isArray(response.data.data)) {
        let results = response.data.data;
        if (selectedLocations && selectedLocations.length > 0) {
          results = results.filter(item =>
            selectedLocations.includes(item.uid?.country),
          );
        }
        setFilteredItems(results);
        setSearchResults(results);
      } else {
        setFilteredItems([]);
        setSearchResults([]);
      }
    } else {
      setSearchInProgress(false);
      setSearchResults([]);
    }
  };
  useEffect(() => {
    if (!searchInProgress) {
      applyFilters();
    }
  }, [
    search,
    selectedCategories,
    selectedCountries,
    years,
    prices,
    selectedLocations,
    selectedCurrency,
    items,
    searchInProgress,
    currency,
    currencyChanged,
  ]);
  useEffect(() => {
    if (query) {
      setSearchQuery(query);
      handleSearch();
    }
  }, [query, items]);
  const handleSearchInputChange = (value: string) => {
    setSearchQuery(value);
  };
  const handleSearchKeyPress = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      await handleSearch();
    }
  };

  const applyFilters = (
    itemsToFilter: any[] = searchResults.length > 0 ? searchResults : items,
  ) => {
    let filtered: any = [...itemsToFilter];
    if (currencyChanged) {
      if (setCurrencyChanged) setCurrencyChanged(false);
    }
    const keywords = search.trim().toLowerCase().split(/\s+/);
    if (keywords.length > 0) {
      filtered = filtered.filter(item => {
        return keywords.some(
          keyword =>
            item?.name?.toLowerCase().includes(keyword) ||
            item?.year?.toString().includes(keyword) ||
            item?.category?.toLowerCase().includes(keyword) ||
            item?.country?.toLowerCase().includes(keyword) ||
            item?.sellerName?.toLowerCase().includes(keyword),
        );
      });
    }
    if (selectedCategories.length > 0) {
      filtered = filtered.filter(item =>
        selectedCategories.some((category: any) =>
          item.categories.some(
            (itemCategory: any) => itemCategory._id === category.value,
          ),
        ),
      );
    }
    if (selectedCountries.length > 0) {
      filtered = filtered.filter(item =>
        selectedCountries.some((country: any) =>
          item.countries.includes(country.value),
        ),
      );
    }
    if (years.min || years.max) {
      const beforeRegex = /^before\s+(\d{1,4})$/i;
      const afterRegex = /^after\s+(\d{1,4})$/i;

      filtered = filtered.filter(item => {
        const itemYears = item.years || []; // Fallback to an empty array if item.years is null or undefined

        // Return false if there are no years to filter in the item
        if (itemYears.length === 0) {
          return false;
        }
        return itemYears.some(itemYear => {
          if (itemYear === null || itemYear === undefined) return false;
          // Convert non-string (e.g., numbers) to strings for consistency
          if (typeof itemYear !== "string") {
            itemYear = itemYear.toString();
          }

          // Case 1: Handle year ranges like "1990-2000"
          if (itemYear.includes("-")) {
            const [startYear, endYear] = itemYear.split("-").map(Number);
            const minCondition = years.min
              ? parseInt(startYear.toString(), 10) >=
                parseInt(years.min.toString(), 10)
              : true;
            const maxCondition = years.max
              ? parseInt(endYear.toString(), 10) <=
                parseInt(years.max.toString(), 10)
              : true;
            if (minCondition && maxCondition) {
            }
            return minCondition && maxCondition;
          }

          // Case 2: Handle "before (year)"
          const beforeMatch = itemYear.match(beforeRegex);

          if (beforeMatch) {
            const beforeYear = parseInt(beforeMatch[1].toString(), 10);

            return (
              beforeYear < parseInt(years.max.toString(), 10) ||
              beforeYear >= parseInt(years.min.toString(), 10)
            );
          }

          // Case 3: Handle "after (year)"
          const afterMatch = itemYear.match(afterRegex);

          if (afterMatch) {
            const afterYear = parseInt(afterMatch[1].toString(), 10);

            return (
              afterYear >= parseInt(years.min.toString(), 10) ||
              afterYear < parseInt(years.max.toString(), 10)
            );
          }

          // Case 4: Handle exact year (numeric or string year)
          const itemYearNum = parseInt(itemYear, 10);

          if (!isNaN(itemYearNum)) {
            const minCondition = years.min
              ? itemYearNum >= parseInt(years.min.toString(), 10)
              : true;

            const maxCondition = years.max
              ? itemYearNum <= parseInt(years.max.toString(), 10)
              : true;

            const final = minCondition && maxCondition;
            if (final) {
            }
            return final;
          }

          return false; // If none of the conditions match, the item is filtered out
        });
      });
    }

    if (prices.min || prices.max) {
      filtered = filtered.filter(item => {
        const convertedItem = convertedPrices.find(
          (priceObj: any) => priceObj.id === item._id,
        );
        if (!convertedItem) {
          return false;
        }
        const itemPrice = convertedItem.convertedPrice;
        const isWithinMinBound = prices.min
          ? itemPrice >= Number(prices.min)
          : true;
        const isWithinMaxBound = prices.max
          ? itemPrice <= Number(prices.max)
          : true;
        return isWithinMinBound && isWithinMaxBound;
      });
    }

    if (selectedLocations && selectedLocations.length > 0) {
      filtered = filtered.filter(item =>
        selectedLocations.includes(item.uid?.country),
      );
    }

    setFilteredItems(filtered);
  };
  useEffect(() => {
    if (filteredItems?.length > 0) {
      getAvailableCategories();
    }
  }, [filteredItems]);

  return (
    <div className="mb-10 mt-4 max-w-[1260px]">
      <div className="md:flex gap-3 lg:flex-row max-lg:flex-wrap  items-end">
        <Search
          value={searchQuery}
          onChange={e => handleSearchInputChange(e.target.value)}
          onKeyDown={handleSearchKeyPress}
          onSearchIconClick={handleSearch}
        />
        <div
          style={{
            // Directly apply background color
            zIndex: 10000, // Apply zIndex here
          }}
          className="flex items-center gap-3 w-full sm:w-full"
        >
          <Select
            title="Category"
            selected={selectedCategories}
            setSelected={setSelectedCategories}
            options={categories}
          />
          <Select
            title="Country"
            selected={selectedCountries}
            setSelected={setSelectedCountries}
            options={filteredCountries}
          />
        </div>
        <div className="flex items-center gap-3 sm:w-full ">
          <Year
            value={years}
            setValue={setYears}
          />
          <Price
            value={prices}
            setValue={setPrices}
            selectedCurrency={selectedCurrency}
          />
        </div>
      </div>
      <div className="mt-2 text-primary">Items: {filteredItems?.length}</div>
      <div className="mt-4 flex gap-3 items-center flex-wrap">
        {selectedCategories.map((itm, idx) => (
          <div
            key={idx}
            className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1"
          >
            <span className="text-sm text-primary">{itm.label}</span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() =>
                setSelectedCategories(prev =>
                  prev?.filter(item => item.value !== itm.value),
                )
              }
            />
          </div>
        ))}
        {selectedCountries.map((itm, idx) => (
          <div
            key={idx}
            className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1"
          >
            <span className="text-sm text-primary">{itm.label}</span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() =>
                setSelectedCountries(prev =>
                  prev?.filter(item => item.value !== itm.value),
                )
              }
            />
          </div>
        ))}
        {(years?.max || years?.min) && (
          <div className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1">
            <span className="text-sm text-primary">
              {years.min} - {years.max}
            </span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() => setYears({ min: "", max: "" })}
            />
          </div>
        )}
        {(prices?.max || prices?.min) && (
          <div className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1">
            <span className="text-sm text-primary">
              {prices.min} - {prices.max}
            </span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() => setPrices({ min: "", max: "" })}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const Select = ({
  title,
  options,
  selected,
  setSelected,
}: {
  title: string;
  options: ISelectOption[];
  selected: any;
  setSelected: React.Dispatch<any>;
}) => {
  console.log("Heyhey", selected)
  return (
    <div className="grid place-items-center   w-full sm:max-w-[300px] mt-3 md:max-w-[200px]">
      <span className="text-primary font-semibold">{title}</span>

      <MultiSelect
        value={selected}
        onChange={setSelected}
        hasSelectAll={false}
        options={options}
        labelledBy={title}
        className="multi_select blog-content-scrollbar scrollable w-full"
        // disableSearch // This disables the search input field
      />
    </div>
  );
};
const Year: React.FC<{
  value: { min: string | number; max: string | number };
  setValue: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;
}> = ({ value, setValue }) => {
  const [localMin, setLocalMin] = useState(value.min);
  const [localMax, setLocalMax] = useState(value.max);

  // Handle min value change on input
  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalMin(e.target.value);
  };

  // Handle max value change on input
  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalMax(e.target.value);
  };

  // Apply the min filter or clear if the field is empty
  const applyMinValue = () => {
    if (!localMin) {
      // Clear the min value if empty
      setLocalMin("");
      setValue(prev => ({
        ...prev,
        min: "",
      }));
    } else if (!localMax || Number(localMin) <= Number(localMax)) {
      // Apply the min value if it's valid
      setValue(prev => ({
        ...prev,
        min: localMin,
      }));
    }
  };

  // Apply the max filter or clear if the field is empty
  const applyMaxValue = () => {
    if (!localMax) {
      // Clear the max value if empty
      setLocalMax("");
      setValue(prev => ({
        ...prev,
        max: "",
      }));
    } else if (!localMin || Number(localMax) >= Number(localMin)) {
      // Apply the max value if it's valid
      setValue(prev => ({
        ...prev,
        max: localMax,
      }));
    }
  };

  return (
    <div className="grid place-items-center   w-full sm:max-w-[150px] mt-3 md:max-w-[200px]">
      <span className="text-primary font-semibold">Year</span>
      <div className="flex items-center justify-center gap-1.5 md:gap-3 w-full rounded-md bg-white border border-primary px-2 md:px-3 py-2 text-sm">
        <input
          type="number"
          value={localMin}
          placeholder="Min"
          min="0"
          onChange={handleMinChange}
          onBlur={applyMinValue} // Apply the filter on blur
          onKeyDown={e => e.key === "Enter" && applyMinValue()} // Apply the filter on enter
          className="bg-[#F5F5F5] text-center w-[50px] font-semibold  md:text-base no-spinner md:w-[50px] px-0 outline-none rounded border border-primary"
        />
        <span className="font-semibold">-</span>
        <input
          type="number"
          value={localMax}
          placeholder="Max"
          min={Number(localMin) || 0}
          onChange={handleMaxChange}
          onBlur={applyMaxValue} // Apply the filter on blur
          onKeyDown={e => e.key === "Enter" && applyMaxValue()} // Apply the filter on enter
          className="bg-[#F5F5F5] text-center w-[50px] font-semibold  md:text-base no-spinner md:w-[50px] px-0 outline-none rounded border border-primary"
        />
      </div>
    </div>
  );
};
const Price: React.FC<{
  value: { min: string | number; max: string | number };
  setValue: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;
  selectedCurrency?: string;
}> = ({ value, setValue, selectedCurrency }) => {
  const currencySymbol =
    selectedCurrency === "USD" ? "$" : selectedCurrency === "EUR" ? "€" : "₪";
  // Local states for price min and max
  const [localMin, setLocalMin] = useState(value.min);
  const [localMax, setLocalMax] = useState(value.max);

  // Handle min value change on input
  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalMin(e.target.value);
  };

  // Handle max value change on input
  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalMax(e.target.value);
  };

  // Apply the min filter or clear if the field is empty
  const applyMinValue = () => {
    if (!localMin) {
      // Clear the min value if empty
      setLocalMin("");
      setValue(prev => ({ ...prev, min: "" }));
    } else if (!localMax || Number(localMin) <= Number(localMax)) {
      // Apply the min value if it's valid
      setValue(prev => ({ ...prev, min: localMin }));
    }
  };

  // Apply the max filter or clear if the field is empty
  const applyMaxValue = () => {
    if (!localMax) {
      // Clear the max value if empty
      setLocalMax("");
      setValue(prev => ({ ...prev, max: "" }));
    } else if (!localMin || Number(localMax) >= Number(localMin)) {
      // Apply the max value if it's valid
      setValue(prev => ({ ...prev, max: localMax }));
    }
  };
  return (
    <div className="grid place-items-center w-full sm:max-w-[100px] mt-3 md:max-w-[200px] ">
      <span className="text-primary font-semibold">Price</span>
      <div className="flex items-center md:text-base  justify-center gap-1.5 md:gap-3 w-full rounded-md bg-white border border-primary px-2 md:px-3 py-2 text-sm">
        <span>{currencySymbol}</span>
        <input
          type="number"
          placeholder="Min"
          value={localMin}
          min={0}
          style={{ MozAppearance: "textfield", WebkitAppearance: "none" }}
          onChange={handleMinChange}
          onBlur={applyMinValue} // Apply the filter on blur
          onKeyDown={e => e.key === "Enter" && applyMinValue()} // Apply the filter on enter
          className="bg-[#F5F5F5] md:text-base text-center font-semibold no-spinner w-[50px] md:w-[60px] px-1 outline-none rounded border border-primary"
        />
        <span className="font-semibold">-</span>
        <input
          type="number"
          placeholder="Max"
          value={localMax}
          min={Number(localMin) || 0} // Ensure max is always >= min
          style={{ MozAppearance: "textfield", WebkitAppearance: "none" }}
          onChange={handleMaxChange}
          onBlur={applyMaxValue} // Apply the filter on blur
          onKeyDown={e => e.key === "Enter" && applyMaxValue()} // Apply the filter on enter
          className="bg-[#F5F5F5]  md:text-base text-center font-semibold no-spinner w-[50px] md:w-[60px] px-1 outline-none rounded border border-primary"
        />
      </div>
    </div>
  );
};
export default Filters;
