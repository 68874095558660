import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FaEdit, FaTrash, FaGripLines } from "react-icons/fa";

interface SortableBlogPostProps {
  post: any;
  setEditingPost: (post: any) => void;
  setIsDeleteMode: (isDeleteMode: boolean) => void;
  openModal: () => void;
  isDragging: boolean;
  activeId: string | null;
}

const SortableBlogPost: React.FC<SortableBlogPostProps> = ({
  post,
  setEditingPost,
  setIsDeleteMode,
  openModal,
  isDragging,
  activeId,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: post._id });

  const style: React.CSSProperties = {
    transform:
      post._id === activeId ? CSS.Transform.toString(transform) : undefined,
    transition: post._id === activeId ? transition : undefined,
    opacity: isDragging ? 0.5 : 1,
    position: "relative",
  };

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={style}
        className="h-full"
      />
    );
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="bg-white overflow-hidden shadow rounded-lg flex flex-col h-full"
    >
      <div className="px-4 py-5 sm:p-6 flex-grow">
        <div className="flex justify-between items-center mb-3">
          <div className="cursor-move">
            <FaGripLines className="text-gray-400" />
          </div>
        </div>

        <h3 className="text-lg font-medium text-gray-900 truncate mb-2">
          {post.title}
        </h3>
        <div
          className="mt-2 max-h-24 overflow-hidden text-sm text-gray-500"
          dangerouslySetInnerHTML={{ __html: post.text }}
        />
      </div>
      <div className="bg-gray-50 px-4 py-4 sm:px-6 mt-auto">
        <span className="text-xs font-semibold text-gray-500 uppercase tracking-wide">
          Countries:
        </span>
        <div className="mb-3">
          <p className="text-sm text-gray-900">{post.countries.join(", ")}</p>
        </div>
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3 justify-end">
          <button
            className="w-full sm:w-auto inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              setEditingPost(post);
              setIsDeleteMode(false);
              openModal();
            }}
          >
            <FaEdit className="mr-2 text-white" />
            <span className="text-white">Edit</span>
          </button>
          <button
            className="w-full sm:w-auto inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            onClick={() => {
              setEditingPost(post);
              setIsDeleteMode(true);
              openModal();
            }}
          >
            <FaTrash className="mr-2 text-white" />
            <span className="text-white">Delete</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SortableBlogPost;
