import React, { useState } from "react";
import Button from "../../common/Button";
import avatar from "../../assets/avatar.jpg";
import contactIcon from "../../assets/profile/contact.png";
import truckIcon from "../../assets/profile/truck.png";
import phoneIcon from "../../assets/profile/phone.png";
import { useParams, Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/auth";
import Modal from "react-modal";
import Api from "../../api/api";
import { FaSpinner } from "react-icons/fa";
import toast from "react-hot-toast";
import Loader from "../../common/Loader";

import topRatedBadge from "../../assets/top_rated_badge.svg";
import { countries } from "./../../utils/constant";
import { isMobile } from "react-device-detect";
Modal.setAppElement("#root");
interface IProps {
  isCollector: boolean;
  user: any;
  itemCount?: number;
  isSameAsUser?: boolean;
  isNotSameAsUser?: boolean;
  isMessagingAllowed?: boolean;
  showMessage?: boolean;
  isDeleteModalOpen?: boolean;
}

const Detail: React.FC<IProps> = ({
  isCollector = false,
  user,
  itemCount,
  isSameAsUser = false,
  isNotSameAsUser = false,
  isMessagingAllowed = true,
  showMessage = false,
  isDeleteModalOpen = false,
}) => {
  const { id } = useParams<any>();
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { user: isUser } = useAuth();
  const [hoveredCountry, setHoveredCountry] = useState<string | null>(null);
  const [hoveredTopRated, setHoveredTopRated] = useState<boolean | null>(null);
  const getCountryName = (countryCode: string) => {
    const temp = countries?.find(c => c.value === countryCode);
    return temp ? temp.label : "Unknown";
  };
  const handleSendMessageClick = () => {
    if (!isUser) history.push("/auth/login");
    setIsMessageModalOpen(true);
  };

  const handleSendMessage = async () => {
    if (!message.trim()) return;
    const params = { receiverId: id, text: message };

    setIsLoading(true);
    try {
      const res = await Api.sendMessage(params);
      if (res.status === 201) {
        setIsMessageModalOpen(false);
        toast.success("Message sent successfully");
      } else {
        console.error("Failed to send message:", res.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!user)
    return (
      <div>
        <Loader />
      </div>
    );
  return (
    <div className="bg-white rounded-2xl p-4 md:p-6">
      <div className="flex items-center justify-between gap-4 flex-wrap mb-6">
        <div className="flex gap-2 md:gap-3">
          <img
            src={user?.avatar || avatar}
            alt="seller avatar"
            className="size-[85px] object-cover md:size-[150px]"
          />

          <div className="flex flex-col justify-between min-w-fit">
            <div
              className="relative"
              onMouseEnter={() => setHoveredCountry(user?.country)}
              onMouseLeave={() => setHoveredCountry(null)}
            >
              <img
                className="w-6 md:w-[50px] rounded"
                src={`https://flagcdn.com/64x48/${user?.country?.toLowerCase()}.png`}
                alt={`${user?.country} flag`}
              />
              {hoveredCountry === user?.country && (
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white  rounded-md px-2 py-1 z-10">
                  {getCountryName(user?.country)}
                </div>
              )}
            </div>
            <span className="hidden md:block text-xs md:text-sm opacity-80">
              {itemCount} items
            </span>
          </div>

          <div className="self-center md:pl-2">
            <div className="flex items-center">
              <p className="text-sm md:text-3xl font-bold mb-1">
                {user?.fname + " " + user?.lname}
              </p>
              {user?.topRated && (
                <div
                  className="relative ml-3"
                  onMouseEnter={() => setHoveredTopRated(true)}
                  onMouseLeave={() => setHoveredTopRated(null)}
                >
                  <img
                    className="w-[15px] sm:max-w-[15px] md:min-w-[25px]"
                    src={topRatedBadge}
                    alt={`Top Rated Seller`}
                  />

                  {/* Tooltip */}
                  {hoveredTopRated && (
                    <div className="absolute whitespace-nowrap bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white  rounded-md px-2 py-1 z-10">
                      {"Top Rated Seller"}
                    </div>
                  )}
                </div>
              )}
            </div>
            <span className="text-sm md:text-xl">{user?.about}</span>
          </div>
        </div>
        <span className="hidden sm:block md:hidden text-xs md:text-sm opacity-80 mt-[-1rem] mb-[-2rem]">
          {itemCount} items
        </span>
        {showMessage && isSameAsUser && (
          <div
            className="flex items-center bg-yellow-400 text-white text-sm font-bold px-4 py-3 mb-6"
            role="alert"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18.33c-.92 0-1.66-.75-1.66-1.66s.75-1.66 1.66-1.66 1.66.75 1.66 1.66-.74 1.66-1.66 1.66zM10 14.5c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1s1 .45 1 1v8.5c0 .55-.45 1-1 1z" />
            </svg>
            <p>
              Your user and items will appear publicly after adding 3 items and
              after approval by our team
            </p>
          </div>
        )}
        {isNotSameAsUser && isMessagingAllowed && (
          <Button
            onClick={handleSendMessageClick}
            className="!w-fit"
          >
            Message
          </Button>
        )}
        {isSameAsUser && (
          <div className="relative">
            <Link
              to={`/${id}/messages`}
              className="rounded-lg bg-primary text-[#ffffff] hidden md:block text-base text-center px-4 py-2"
            >
              My Messages
            </Link>
            {user?.unreadConversationsCount > 0 && !isMobile && (
              <span className="absolute top-[-5px] left-[-5px] md:top-[-11px] left-[-11px] bg-red-500 text-white text-xs md:text-lg font-bold rounded-full h-5 w-5 md:h-7 md:w-7 flex items-center justify-center">
                {user.unreadConversationsCount}
              </span>
            )}
          </div>
        )}
      </div>

      <div className="flex gap-4">
        <div className="min-w-[28px] md:min-w-[40px]"></div>
        <p className="font-medium max-md:text-sm">
          Member since {new Date(user?.createdAt).toLocaleDateString("en-GB")}
        </p>
      </div>
      <div className="h-[2px] w-[130px] bg-[#69696999] my-2.5 ml-[44px] md:ml-[56px]"></div>
      <div className="flex gap-4 items-center max-md:text-sm">
        <img
          src={contactIcon}
          className="size-7 md:size-[40px] "
          alt="contact"
        />
        <div className="whitespace-pre-wrap line-clamp-5 break-words max-w-[80vw]">
          {user?.description || "No description"}
        </div>
      </div>
      {!isCollector && (
        <>
          <div className="h-[2px] w-[130px] bg-[#69696999] my-2.5 ml-[44px] md:ml-[56px]"></div>
          <div className="flex gap-4 items-center max-md:text-sm">
            <img
              src={truckIcon}
              className="size-7 md:size-[40px]"
              alt="truck"
            />
            <p className="whitespace-pre-wrap line-clamp-3 break-words max-w-[80vw]">
              {user?.deliveryOption || "No delivery info"}
            </p>
          </div>
          <div className="h-[2px] w-[130px] bg-[#69696999] my-2.5 ml-[44px] md:ml-[56px]"></div>
          <div className="flex gap-4 max-md:text-sm">
            <img
              src={phoneIcon}
              className="size-7 md:size-[40px]"
              alt="phone"
            />
            <p className="whitespace-pre-wrap line-clamp-3 break-words max-w-[80vw]">
              {" "}
              {user?.contact || "No delivery info"}
            </p>
          </div>
          <div className="flex justify-end gap-4 mt-4">
            <Link
              to={`/user-items/${id}`}
              className="text-primary hover:underline text-md"
            >
              Items
            </Link>
            <br />
            <Link
              to={`/collection/all/${id}`}
              className="text-primary hover:underline text-md"
            >
              Collections
            </Link>
          </div>
        </>
      )}
      <Modal
        isOpen={isMessageModalOpen}
        onRequestClose={() => setIsMessageModalOpen(false)}
        contentLabel="Send Message"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto md:min-w-[40%] mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2>Send a Message to {user?.fname + " " + user?.lname}</h2>
        <textarea
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Type your message here..."
          className="w-full p-2 border border-gray-300 rounded"
          rows={5}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={() => setIsMessageModalOpen(false)}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSendMessage}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Detail;
