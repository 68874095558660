import React from "react";
import { IButtonProps } from "../types/common";

const Button: React.FC<IButtonProps> = ({ children, className, ...rest }) => {
  return (
    <button
      type="button"
      className={`rounded-lg w-full bg-primary text-[#ffffff] text-sm md:text-base text-center px-4 py-2 ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
