import React, { useState, useEffect } from "react";
import BlogPostForm from "../../components/Admin/BlogPostForm";
import Api from "../../api/api";
import { countries } from "../../utils/constant";
import AdminHeader from "../../components/Admin/AdminHeader";
import Footer from "../../common/Footer";
import { FaPlus, FaGlobe } from "react-icons/fa";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  DragEndEvent,
  DragStartEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableBlogPost from "./SortableBlogPost";

interface BlogPost {
  _id: string;
  title: string;
  text: string;
  countries: string[];
  [key: string]: any;
}

const BlogPostList: React.FC = () => {
  const [allBlogPosts, setAllBlogPosts] = useState<BlogPost[]>([]);
  const [filteredBlogPosts, setFilteredBlogPosts] = useState<BlogPost[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("All");
  const [editingPost, setEditingPost] = useState<BlogPost | null>(null);
  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    const res = await Api.getAllBlogPost();
    setAllBlogPosts(res?.data?.data);
    setFilteredBlogPosts(res?.data?.data);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setEditingPost(null);
  };

  const handleCountryFilter = (country: string) => {
    setSelectedCountry(country);
    if (country === "All") {
      setFilteredBlogPosts(allBlogPosts);
    } else {
      setFilteredBlogPosts(
        allBlogPosts.filter(post => post.countries.includes(country)),
      );
    }
  };

  const handleSave = async (id: string | null, blogPostData: any) => {
    if (id) {
      await Api.updateBlogPost(id, blogPostData);
    } else {
      await Api.createBlogPost(blogPostData);
    }
    closeModal();
    fetchBlogPosts();
  };

  const handleDelete = async (id: string) => {
    await Api.deleteBlogPost(id);
    closeModal();
    fetchBlogPosts();
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id && over) {
      setFilteredBlogPosts(posts => {
        const oldIndex = posts.findIndex(post => post._id === active.id);
        const newIndex = posts.findIndex(post => post._id === over.id);

        const newPosts = [...posts];
        const [movedItem] = newPosts.splice(oldIndex, 1);
        newPosts.splice(newIndex, 0, movedItem);

        const orderedIds = newPosts.map(post => post._id);
        Api.reorderBlogPosts(orderedIds);

        return newPosts;
      });
    }

    setActiveId(null);
  };

  return (
    <>
      <AdminHeader />
      <main className="bg-gray-100 min-h-screen">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {isModalOpen && (
            <BlogPostForm
              existingPost={editingPost}
              onSave={handleSave}
              onDelete={isDeleteMode ? handleDelete : undefined}
              isDeleteMode={isDeleteMode}
              onCancel={closeModal}
              isOpen={isModalOpen}
            />
          )}

          <div className="mb-8">
            <h2 className="text-2xl sm:text-3xl font-extrabold text-gray-900 mb-4">
              Blog Posts
            </h2>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="flex items-center w-full sm:w-auto">
                <FaGlobe className="text-gray-500 mr-2" />
                <select
                  value={selectedCountry}
                  onChange={e => handleCountryFilter(e.target.value)}
                  className="block w-full sm:w-auto pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-sm"
                >
                  <option value="All">All Countries</option>
                  {countries.map(country => (
                    <option
                      key={country.value}
                      value={country.value}
                    >
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-sm shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  setEditingPost(null);
                  setIsDeleteMode(false);
                  openModal();
                }}
              >
                <FaPlus className="mr-2" /> Create Blog Post
              </button>
            </div>
          </div>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={filteredBlogPosts.map(post => post._id)}
              strategy={verticalListSortingStrategy}
            >
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {filteredBlogPosts.map(post => (
                  <SortableBlogPost
                    key={post._id}
                    post={post}
                    setEditingPost={setEditingPost}
                    setIsDeleteMode={setIsDeleteMode}
                    openModal={openModal}
                    isDragging={post._id === activeId}
                    activeId={activeId}
                  />
                ))}
              </div>
            </SortableContext>
            <DragOverlay>
              {activeId ? (
                <div className="bg-white overflow-hidden shadow rounded-lg flex flex-col h-full opacity-90 border-2 border-blue-500">
                  <div className="px-4 py-5 sm:p-6 flex-grow">
                    <h3 className="text-lg font-medium text-gray-900 truncate mb-2">
                      {
                        filteredBlogPosts.find(post => post._id === activeId)
                          ?.title
                      }
                    </h3>
                    <div
                      className="mt-2 max-h-24 overflow-hidden text-sm text-gray-500"
                      dangerouslySetInnerHTML={{
                        __html:
                          filteredBlogPosts.find(post => post._id === activeId)
                            ?.text || "",
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BlogPostList;
