import React, { useState, useEffect, useRef } from "react";
import Api from "../api/api";
import { countries } from "../utils/constant";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { FaSearch } from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
interface BlogPost {
  _id: string;
  title: string;
  text: string;
  countries: string[];
  image?: string;
  createdAt: string;
}
interface Country {
  value: string;
  label: string;
}
const BlogPosts: React.FC = () => {
  const [allBlogPosts, setAllBlogPosts] = useState<BlogPost[]>([]);
  const [filteredBlogPosts, setFilteredBlogPosts] = useState<BlogPost[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("Everywhere");
  const [availableCountries, setAvailableCountries] = useState<Country[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [shareMenuOpen, setShareMenuOpen] = useState<string | null>(null);
  const [expandedPosts, setExpandedPosts] = useState<Set<string>>(new Set());
  const [scrollablePostIds, setScrollablePostIds] = useState<Set<string>>(
    new Set(),
  );
  const shareMenuRef = useRef<HTMLDivElement>(null);
  const postsPerPage: number = 9;
  useEffect(() => {
    fetchBlogPosts();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    return () => window.removeEventListener("resize", checkScrollable);
  }, [filteredBlogPosts, expandedPosts]);
  const fetchBlogPosts = async () => {
    try {
      const res = await Api.getAllBlogPost();
      const blogPosts: BlogPost[] = res?.data?.data;
      setAllBlogPosts(blogPosts);
      setFilteredBlogPosts(blogPosts);
      const countriesWithPosts = new Set(
        blogPosts.flatMap(post => post.countries),
      );
      const availableCountryObjects = countries.filter(country =>
        countriesWithPosts.has(country.value),
      );
      setAvailableCountries([
        { value: "Everywhere", label: "Everywhere" },
        ...availableCountryObjects.sort((a, b) =>
          a.label.localeCompare(b.label),
        ),
      ]);
      const locationResponse = await fetch("https://ipapi.co/json/");
      const locationData = await locationResponse.json();
      const userCountry = locationData.country || "Everywhere";
      const defaultCountry = availableCountryObjects.find(
        country => country.value === userCountry,
      );

      setSelectedCountry(defaultCountry?.value || "Everywhere");
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  };
  const checkScrollable = () => {
    const scrollableIds = new Set<string>();
    filteredBlogPosts.forEach(post => {
      const element = document.getElementById(`post-content-${post._id}`);
      if (element && element.scrollHeight > element.clientHeight) {
        scrollableIds.add(post._id);
      }
    });
    setScrollablePostIds(scrollableIds);
  };
  const handleCountryFilter = (countryValue: string) => {
    setSelectedCountry(countryValue);
    filterPosts(countryValue, searchTerm);
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    filterPosts(selectedCountry, newSearchTerm);
  };
  const filterPosts = (countryValue: string, term: string) => {
    let filtered = allBlogPosts;
    if (countryValue !== "Everywhere") {
      filtered = filtered.filter(post => post.countries.includes(countryValue));
    }
    if (term) {
      const lowercaseTerm = term.toLowerCase();
      filtered = filtered.filter(
        post =>
          post.title.toLowerCase().includes(lowercaseTerm) ||
          post.text.toLowerCase().includes(lowercaseTerm),
      );
    }
    setFilteredBlogPosts(filtered);
    setCurrentPage(1);
  };
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredBlogPosts.slice(
    indexOfFirstPost,
    indexOfLastPost,
  );
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      shareMenuRef.current &&
      !shareMenuRef.current.contains(event.target as Node)
    ) {
      setShareMenuOpen(null);
    }
  };
  const getShareUrl = (postId: string) => {
    return `${window.location.origin}/blog/${postId}`;
  };

  return (
    <>
      <Header />
      <main className="p-4 md:p-6 mx-auto max-w-[1560px] bg-gray-50 min-h-screen">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <div className="flex items-center mb-4 md:mb-0 w-full md:w-auto">
            <label
              htmlFor="country-select"
              className="font-medium mr-2 text-gray-600"
            >
              <b>Showing from</b>
            </label>
            <select
              id="country-select"
              value={selectedCountry}
              onChange={e => handleCountryFilter(e.target.value)}
              className="p-2 border border-gray-300 rounded-md w-full md:w-auto"
            >
              {availableCountries.map(country => (
                <option
                  key={country.value}
                  value={country.value}
                >
                  {country.label}
                </option>
              ))}
            </select>
          </div>
          <div className="relative w-full md:w-auto mt-4 md:mt-0">
            <input
              type="text"
              placeholder="Search posts..."
              value={searchTerm}
              onChange={handleSearch}
              className="p-2 pl-8 border border-gray-300 rounded-md w-full"
            />
            <FaSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {currentPosts.length > 0 ? (
            currentPosts.map(post => (
              <div
                key={post._id}
                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow flex flex-col"
              >
                {post.image && (
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-48 object-cover mb-4 rounded"
                  />
                )}
                <h3 className="text-xl font-semibold text-gray-700 mb-2">
                  {post.title}
                </h3>
                <p className="text-sm text-gray-500 mb-2">
                  {new Date(post.createdAt).toLocaleDateString()}
                </p>
                <div
                  id={`post-content-${post._id}`}
                  className={`blog-content-scrollbar ${scrollablePostIds.has(post._id) ? "scrollable" : ""}`}
                  style={{
                    height: expandedPosts.has(post._id) ? "16rem" : "12rem",
                    overflowY: "auto",
                    marginBottom: "1rem",
                    paddingRight: "0.5rem",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: post.text }}
                    className="blog-content pb-2"
                  />
                </div>
                <div className="flex justify-between items-center mt-auto pt-4 border-t border-gray-200">
                  {/* <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => handleShareClick(post._id)}
                  >
                    <FaShare />
                  </button> */}
                </div>
                {shareMenuOpen === post._id && (
                  <div
                    ref={shareMenuRef}
                    className="absolute bottom-16 right-4 bg-white p-2 rounded-sm shadow-lg flex space-x-2"
                  >
                    <WhatsappShareButton
                      url={getShareUrl(post._id)}
                      title={post.title}
                    >
                      <WhatsappIcon
                        size={32}
                        round
                      />
                    </WhatsappShareButton>
                    <TwitterShareButton
                      url={getShareUrl(post._id)}
                      title={post.title}
                    >
                      <TwitterIcon
                        size={32}
                        round
                      />
                    </TwitterShareButton>
                    <FacebookShareButton
                      url={getShareUrl(post._id)}
                      hashtag="#blogpost"
                    >
                      <FacebookIcon
                        size={32}
                        round
                      />
                    </FacebookShareButton>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="text-gray-500 col-span-full">
              No blog posts available.
            </p>
          )}
        </div>
        {filteredBlogPosts.length > postsPerPage && (
          <div className="flex justify-center mt-6">
            {Array.from(
              { length: Math.ceil(filteredBlogPosts.length / postsPerPage) },
              (_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`mx-1 px-3 py-1 rounded ${
                    currentPage === i + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {i + 1}
                </button>
              ),
            )}
          </div>
        )}
      </main>
      <Footer />
    </>
  );
};
export default BlogPosts;
