import React from "react";
import { Redirect, Route } from "react-router-dom";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME } from "./utils/constant";
import { IPublicProps } from "./protectedRoute";

export default function Auth({ component: Cmp, ...rest }: IPublicProps) {
  return (
    <Route
      {...rest}
      exact
      render={props =>
        Cookies.get(REACT_APP_KEY_NAME) === undefined ? (
          <Cmp {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}
