import React, { useState } from "react";
import ReactSelect from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Modal from "react-modal";
import { countries } from "../../utils/constant";
import { FaTimes } from "react-icons/fa";

Modal.setAppElement("#root");

const BlogPostForm = ({
  existingPost,
  onSave,
  onDelete,
  isDeleteMode,
  isOpen,
  onCancel,
}) => {
  const [title, setTitle] = useState(existingPost?.title || "");
  const [text, setText] = useState(existingPost?.text || "");
  const [selectedCountries, setSelectedCountries] = useState(
    existingPost?.countries
      ? existingPost.countries.map(countryCode => {
          const matchedCountry = countries.find(c => c.value === countryCode);
          return matchedCountry || { value: countryCode, label: countryCode };
        })
      : [],
  );

  const handleSave = async () => {
    const blogPostData = {
      title,
      text,
      countries: selectedCountries.map(c => c.value),
    };

    if (existingPost) {
      await onSave(existingPost._id, blogPostData);
    } else {
      await onSave(null, blogPostData);
    }
  };

  const handleCountryChange = selectedOptions => {
    setSelectedCountries(selectedOptions || []);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      border: "none",
      borderRadius: "8px",
      maxWidth: "90%",
      width: "600px",
      maxHeight: "90vh",
      overflow: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel={isDeleteMode ? "Delete Blog Post" : "Edit Blog Post"}
      style={customStyles}
    >
      <div className="bg-white rounded-lg shadow-xl">
        <div className="flex justify-between items-center border-b p-4">
          <h2 className="text-xl sm:text-2xl font-semibold text-gray-800">
            {isDeleteMode
              ? "Delete Blog Post"
              : existingPost
                ? "Edit Blog Post"
                : "Create Blog Post"}
          </h2>
          <button
            onClick={onCancel}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes size={20} />
          </button>
        </div>

        {isDeleteMode ? (
          <div className="p-4 sm:p-6">
            <p className="text-base sm:text-lg text-gray-700 mb-6">
              Are you sure you want to delete this blog post?
            </p>
            <div className="flex justify-end gap-3 sm:gap-4">
              <button
                className="px-3 sm:px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-200 text-sm sm:text-base"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className="px-3 sm:px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-200 text-sm sm:text-base"
                onClick={() => onDelete(existingPost._id)}
              >
                Delete
              </button>
            </div>
          </div>
        ) : (
          <div className="p-4 sm:p-6">
            <input
              type="text"
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder="Title"
              className="mb-4 p-2 sm:p-3 border border-gray-300 rounded-md w-full focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base"
            />

            <div className="mb-4">
              <ReactQuill
                value={text}
                onChange={setText}
                theme="snow"
                placeholder="Write your blog content here..."
                className="h-48 sm:h-64"
              />
            </div>

            <label className="block mt-8 sm:mt-11 mb-2 text-base sm:text-lg font-medium text-gray-700">
              Select Countries:
            </label>

            <ReactSelect
              value={selectedCountries}
              onChange={handleCountryChange}
              options={countries}
              closeMenuOnSelect={false}
              isMulti
              className="mb-6"
              classNamePrefix="select"
              placeholder="Select countries"
              styles={{
                control: provided => ({
                  ...provided,
                  borderColor: "#E2E8F0",
                  "&:hover": {
                    borderColor: "#CBD5E0",
                  },
                }),
                multiValue: provided => ({
                  ...provided,
                  backgroundColor: "#EBF4FF",
                }),
                multiValueLabel: provided => ({
                  ...provided,
                  color: "#2B6CB0",
                }),
                multiValueRemove: provided => ({
                  ...provided,
                  color: "#2B6CB0",
                  ":hover": {
                    backgroundColor: "#BFDBFE",
                    color: "#2B6CB0",
                  },
                }),
              }}
            />

            <div className="flex justify-end gap-3 sm:gap-4">
              <button
                className="px-3 sm:px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-200 text-sm sm:text-base"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className="px-3 sm:px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200 text-sm sm:text-base"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BlogPostForm;
