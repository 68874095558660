import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME, ROLES } from "./utils/constant";
import { JwtPayload, jwtDecode } from "jwt-decode";

export interface IPublicProps extends RouteProps {
  component: React.ComponentType<any>;
  exact?: boolean;
}

export default function Public({ component: Cmp, ...rest }: IPublicProps) {
  const token = Cookies.get(REACT_APP_KEY_NAME);
  const decode_token: JwtPayload & { role?: number } = token
    ? jwtDecode(token)
    : {};

  return (
    <Route
      {...rest}
      exact
      render={props =>
        Cookies.get(REACT_APP_KEY_NAME) !== undefined &&
        localStorage.getItem(REACT_APP_KEY_NAME) &&
        decode_token?.role === ROLES.USER ? (
          <Cmp {...props} />
        ) : (
          <>
            {Cookies.remove(REACT_APP_KEY_NAME)}
            <Redirect to="/auth/login" />
          </>
        )
      }
    />
  );
}
