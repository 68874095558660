// src/pages/Favorites.tsx
import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Item from "../components/Items/Item";
import Api from "../api/api";
import { useAuth } from "../context/auth";
import { FaSpinner } from "react-icons/fa6";
import { useCurrency } from "../context/currency";
import { useLocationContext } from "../context/location";
import { isCountryMatch } from "../utils/helpers";

const Favorites: React.FC = () => {
  const [favorites, setFavorites] = useState<any[]>([]);
  const [filteredFavorites, setFilteredFavorites] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { userCurrency, convert } = useCurrency();
  useEffect(() => {
    fetchFavorites();
  }, [user?._id]);
  const { selectedLocation: selectedLocations } = useLocationContext();
  useEffect(() => {
    if (selectedLocations) {
      const filtered = favorites.filter(item =>
        isCountryMatch(item?.uid?.country, selectedLocations),
      );

      setFilteredFavorites(filtered);
    } else {
      setFilteredFavorites(favorites);
    }
  }, [favorites, userCurrency, selectedLocations]);
  const fetchFavorites = async () => {
    try {
      const res = await Api.getFavorites(user?._id);
      if (res.status === 200) {
        setFavorites(res?.data?.data);
      }
    } catch (error) {
      console.error("Failed to fetch favorite items", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-1 md:px-6 w-full">
            <h1 className="text-2xl md:text-3xl font-bold mb-6">
              My Favorites
            </h1>
            {loading ? (
              <div className="flex justify-center items-center py-10">
                <FaSpinner className="animate-spin text-3xl text-primary" />
              </div>
            ) : (
              <div className="grid gap-2 md:gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                {filteredFavorites.length > 0 ? (
                  filteredFavorites.map((item, idx) => (
                    <Item
                      key={item._id || idx}
                      user_data={item.uid}
                      isPinned={item.pinned}
                      isFeatured={item.isFeatured}
                      isCountry={item.isCountry}
                      isSeller={user?._id === item.uid._id}
                      name={item.name}
                      uid={item.uid._id}
                      description={item.description}
                      country={item.country}
                      photos={item.photos}
                      currency={userCurrency}
                      price={convert(item.price, item.currency, userCurrency)}
                      year={item.year}
                      id={item._id}
                      isSellerPage={false}
                      hidden={item.hidden}
                      category={item.categories}
                      createdAt={item.createdAt}
                      updatedAt={item.updatedAt}
                    />
                  ))
                ) : (
                  <p className="text-center text-lg">
                    No favorite items found.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Favorites;
