import { useState, useEffect } from "react";
import {
  getUserCurrency,
  getExchangeRates,
  convertPrice,
  convertPriceAsync,
} from "../utils/currencyUtils"; // Utility functions
import { IUser } from "../types/auth";
import Api from "../api/api";

export const useCurrency = (
  user: IUser | null,
  europeanCountries: string[],
) => {
  const [userCurrency, setUserCurrency] = useState<string>("USD");
  const [exchangeRates, setExchangeRates] = useState<Record<string, number>>(
    {},
  );

  useEffect(() => {
    const fetchCurrencyAndRates = async () => {
      let currency = "USD";

      // If the user has a lastChosenCurrency, use it
      if (user?.lastChosenCurrency) {
        currency = user.lastChosenCurrency;
      } else {
        // Otherwise, determine currency based on location
        currency = await getUserCurrency();
      }

      const rates = await getExchangeRates(currency);

      setUserCurrency(currency);
      setExchangeRates(rates);
    };

    fetchCurrencyAndRates();
  }, [user, europeanCountries]);

  const convert = (
    price: number,
    baseCurrency: string,
    targetCurrency: string,
  ): number => {
    return convertPrice(price, baseCurrency, targetCurrency, exchangeRates);
  };
  const convertAsync = async (
    price: number,
    baseCurrency: string,
    targetCurrency: string,
  ): Promise<number> => {
    // Call the async convertPrice function and await the result
    return await convertPriceAsync(
      price,
      baseCurrency,
      targetCurrency,
      exchangeRates,
    );
  };
  // Function to manually update user's chosen currency
  const updateUserCurrency = async (newCurrency: string) => {
    setUserCurrency(newCurrency);
    if (user) {
      try {
        await Api.updateMyProfile({ lastChosenCurrency: newCurrency }); // Assuming this API exists
      } catch (error) {
        console.error("Error updating user's last chosen currency:", error);
      }
    }
  };

  return {
    userCurrency,
    exchangeRates,
    convert,
    convertAsync,
    updateUserCurrency,
  };
};
