import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Auth from "../../layout/Auth";
import Input from "../../common/Input";
import Button from "../../common/Button";
import Api from "../../api/api";

const Forgot: React.FC = () => {
  const history = useHistory();
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({ email: "" });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsDisable(true);

    try {
      const res = await Api.forgotPassword(data);
      if (res.status === 200) {
        history.replace("/auth/login");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ email: "" });
      setIsDisable(false);
    };
  }, []);

  return (
    <Auth
      title="Forgot Password"
      submitHandle={handleSubmit}
    >
      <Input
        type="email"
        title="email"
        name="email"
        value={data?.email}
        onChange={handleInputChange}
        placeholder="Enter Email"
        required
      />

      <Button
        className="mt-7 mb-2"
        type="submit"
        disabled={isDisable}
      >
        Forgot
      </Button>

      <div className="text-sm flex gap-1 items-center justify-center text-secondary">
        Don’t have an account?
        <Link
          to="/auth/select-signup"
          className="text-primary font-medium"
        >
          Sign Up
        </Link>
      </div>
    </Auth>
  );
};

export default Forgot;
