import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Auth from "../../layout/Auth";
import Input from "../../common/Input";
import Button from "../../common/Button";
import Api from "../../api/api";
import toast from "react-hot-toast";

const initialData = {
  password: "",
  confirmPassword: "",
};

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<{ token?: string }>();
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({ ...initialData });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (data.password !== data.confirmPassword) {
      return toast.error("Password doesn't match");
    }

    setIsDisable(true);
    try {
      const res = await Api.resetPassword({ token, ...data });
      if (res.status === 200) {
        history.replace("/auth/login");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);

  return (
    <Auth
      title="Reset Password"
      submitHandle={handleSubmit}
    >
      <Input
        type="password"
        className="mt-6"
        title="password"
        name="password"
        value={data?.password}
        onChange={handleInputChange}
        placeholder="Enter Password"
        disabled={isDisable}
        required
      />
      <Input
        type="password"
        className="mt-6"
        title="Confirm Password"
        name="confirmPassword"
        value={data?.confirmPassword}
        onChange={handleInputChange}
        placeholder="Enter Confirm Password"
        disabled={isDisable}
        required
      />

      <Button
        className="mt-7 mb-2"
        type="submit"
        disabled={isDisable}
      >
        Reset
      </Button>

      <div className="text-sm flex gap-1 items-center justify-center text-secondary">
        Don’t have an account?
        <Link
          to="/auth/select-signup"
          className="text-primary font-medium"
        >
          Sign Up
        </Link>
      </div>
    </Auth>
  );
};

export default ResetPassword;
