import React, { useEffect, useState } from "react";
import Api from "../../api/api"; // Your backend API calls
import AdminHeader from "../../components/Admin/AdminHeader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Line } from "react-chartjs-2";
import { format, subDays, startOfWeek } from "date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);
const AdminDashboard = () => {
  const [data, setData] = useState<any>({
    usersOverview: {},
    itemsOverview: {},
    websitePerformance: {},
    itemsAddedPerDay: [],
    messagesSentPerDay: [],
  });
  const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState<Date>(new Date());
  useEffect(() => {
    async function fetchStatistics() {
      const response = await Api.getStatistics(); // Call your backend
      if (response.status === 200) {
        setData(response.data);
      }
    }
    fetchStatistics();
  }, []);
  const filterDataByDateRange = (dataArray, startD, endD) => {
    return dataArray?.filter(entry => {
      const entryDate = new Date(entry.date); // Assuming your entry has a `date` property
      return entryDate >= startD && entryDate <= endD;
    });
  };
  const filteredItemsAddedPerDay = filterDataByDateRange(
    data.itemsOverview?.itemsAddedPerDay,
    startDate,
    endDate,
  );
  const filteredMessagesSentPerDay = filterDataByDateRange(
    data.messagesOverview?.messagesSentPerDay,
    startDate,
    endDate,
  );
  return (
    <>
      <AdminHeader />
      <div className="container mx-auto p-6 space-y-6">
        {/* Header */}
        <h1 className="text-3xl font-semibold text-gray-800">
          Admin Dashboard
        </h1>

        {/* Overview Sections */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* User Overview */}
          <OverviewCard
            title="User Overview"
            stats={[
              { label: "Total Users", value: data?.usersOverview?.totalUsers },
              {
                label: "New Sign-Ups (24h)",
                value: data?.usersOverview?.newUsers?.["24h"],
              },
              {
                label: "New Sign-Ups (7d)",
                value: data?.usersOverview?.newUsers?.["7d"],
              },
              {
                label: "New Sign-Ups (30d)",
                value: data?.usersOverview?.newUsers?.["30d"],
              },
              {
                label: "Active Users (24h)",
                value: data?.usersOverview?.activeUsers,
              },
            ]}
          />

          {/* Item Overview */}
          <OverviewCard
            title="Item Overview"
            stats={[
              { label: "Total Items", value: data?.itemsOverview?.totalItems },
              {
                label: "New Items (24h)",
                value: data?.itemsOverview?.newItems?.["24h"],
              },
              {
                label: "New Items (7d)",
                value: data?.itemsOverview?.newItems?.["7d"],
              },
              {
                label: "New Items (30d)",
                value: data?.itemsOverview?.newItems?.["30d"],
              },
              {
                label: "Pending Items",
                value: data?.itemsOverview?.pendingApprovals,
              },
              {
                label: "Hidden Items",
                value: data?.itemsOverview?.hiddenItems,
              },
            ]}
          />

          {/* Website Overview */}
          <OverviewCard
            title="Website Overview"
            stats={[
              {
                label: "Total Visits (24h)",
                value: data?.websitePerformance?.visits?.["24h"],
              },
              {
                label: "Total Visits (7d)",
                value: data?.websitePerformance?.visits?.["7d"],
              },
              {
                label: "Total Visits (30d)",
                value: data?.websitePerformance?.visits?.["30d"],
              },
              {
                label: "Average Load Time",
                value: data?.websitePerformance?.averagePageLoadTime,
              },
              {
                label: "Top Countries",
                value: "Not Available",
              },
            ]}
          />
        </div>

        <div className="flex !mt-[0.2rem] space-x-4">
          <div>
            <label className="block text-gray-700">Start Date:</label>
            <DatePicker
              selected={startDate}
              onChange={date => {
                if (date !== null) {
                  setStartDate(date);
                }
              }}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={endDate}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <div>
            <label className="block text-gray-700">End Date:</label>
            <DatePicker
              selected={endDate}
              onChange={date => {
                if (date !== null) {
                  setEndDate(date);
                }
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
              dateFormat="yyyy-MM-dd"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Items Added Per Day Chart */}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Items Added Per Day
            </h2>
            <LineChart data={filteredItemsAddedPerDay} />
          </div>

          {/* Messages Sent Per Day Chart */}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Messages Sent Per Day
            </h2>
            <LineChart data={filteredMessagesSentPerDay} />
          </div>
        </div>
      </div>
    </>
  );
};

const OverviewCard = ({ title, stats }) => (
  <div className="bg-white shadow rounded-lg p-4">
    <h2 className="text-xl font-semibold text-gray-800 mb-4">{title}</h2>
    <ul className="space-y-3">
      {stats.map((stat, idx) => (
        <li
          key={idx}
          className="flex justify-between text-gray-600"
        >
          <span>{stat.label}</span>
          <span className="font-bold">{stat.value || "-"}</span>
        </li>
      ))}
    </ul>
  </div>
);
// LineChart Component
const LineChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>No data available.</div>;
  }

  const chartData = {
    labels: data.map(entry => entry.date),
    datasets: [
      {
        label: "Count",
        data: data.map(entry => entry.count),
        fill: false,
        backgroundColor: "#3b82f6",
        borderColor: "#3b82f6",
      },
    ],
  };

  return (
    <Line
      data={chartData}
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: false, // Set to true if you want to display the legend
          },
          title: {
            display: false, // Set to true if you want to display the title
          },
        },
        scales: {
          x: {
            type: "category",
            title: {
              display: true,
              text: "Date",
            },
          },
          y: {
            title: {
              display: true,
              text: "Count",
            },
            beginAtZero: true,
            ticks: {
              precision: 0,
            },
          },
        },
      }}
    />
  );
};

export default AdminDashboard;
