import React from "react";
import { IoSearch } from "react-icons/io5";

const Search: React.FC<{
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onSearchIconClick?: () => void;
}> = ({ value, onChange, onKeyDown, onSearchIconClick }) => {
  return (
    <div className="bg-[#E9E9E9] border border-black rounded-lg relative w-full max-w-[400px]">
      <input
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        className="bg-transparent outline-none border-0 w-full h-full px-3 py-2 pr-8"
        placeholder="Search"
      />
      <IoSearch
        size={22}
        fill="var(--primary)"
        className="absolute right-2 top-[10px] cursor-pointer"
        onClick={onSearchIconClick}
      />
    </div>
  );
};

export default Search;
