// src/pages/HiddenCollections.tsx
import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Collection from "../../components/Collection";
import Api from "../../api/api";
import { FaSpinner } from "react-icons/fa";
import { useAuth } from "../../context/auth";

const HiddenCollections = () => {
  const [hiddenCollections, setHiddenCollections] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    fetchHiddenCollections();
  }, [user]);

  const fetchHiddenCollections = async () => {
    setLoading(true);
    try {
      const userId = user?._id;
      const collectionsRes = await Api.getAllCollection(userId);
      const hiddenCollectionsData = collectionsRes?.data?.data.filter(
        (collection: any) => collection.hidden,
      );
      setHiddenCollections(hiddenCollectionsData);
    } catch (error) {
      console.error("Failed to fetch hidden collections:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCollectionUpdate = async () => {
    await fetchHiddenCollections();
  };

  const handleCollectionDelete = async (collectionId: string) => {
    setHiddenCollections(prevCollections =>
      prevCollections.filter(collection => collection._id !== collectionId),
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-10">
        <FaSpinner className="animate-spin text-3xl text-primary" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <p className="text-center text-2xl md:text-3xl font-bold mb-5 text-[#D03531]">
              Hidden Collections
            </p>
            <div className="p-3 md:p-5 rounded-2xl bg-white">
              <div className="grid justify-items-center gap-y-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {hiddenCollections?.map((collection, idx) => (
                  <Collection
                    key={collection._id || idx}
                    collection={collection}
                    isSeller={true}
                    onCollectionUpdate={handleCollectionUpdate}
                    onDelete={() => handleCollectionDelete(collection._id)}
                  />
                ))}
              </div>
              {hiddenCollections?.length === 0 && (
                <p className="text-center">No hidden collections found.</p>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default HiddenCollections;
