import React from "react";
import { ISelectProps } from "../types/common";

const Select: React.FC<ISelectProps> = ({
  title,
  options,
  className,
  ...rest
}) => {
  return (
    <div className={`relative mt-4 ${className}`}>
      <label className="capitalize block mb-1 text-secondary text-[15px]">
        {title}
      </label>
      <select
        className="w-full border border-[#A6A9ACB2] rounded-lg px-4 py-3 text-[15px]"
        {...rest}
      >
        {options?.map((itm, idx) => (
          <option
            key={idx}
            value={itm.value}
            className="capitalize"
          >
            {itm.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
