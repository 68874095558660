import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import SelectSignup from "../pages/auth/selectSignup";
import Forgot from "../pages/auth/forgot";
import Verify from "../pages/auth/verify";
import ResetPassword from "../pages/auth/reset";
import Contact from "../pages/contact";
import Home from "../pages/home";
import Items from "../pages/items";
import Sellers from "../pages/sellers";
import Seller from "../pages/seller";
import SingleItem from "../pages/item";

import FeaturedItems from "../pages/client/featured";
import Hiddens from "../pages/client/hiddens";
import HiddenCollections from "../pages/client/hiddenCollections";
import HiddenItems from "../pages/client/hiddenItems";
import NewItem from "../pages/client/Item/new";
import EditProfile from "../pages/client/edit-profile";
import PasswordChange from "../pages/client/password";
import AccountVisiblity from "../pages/client/accountVisibility";
import MessagesPage from "../pages/client/message_board";
import Search from "../pages/search";
import CollectionPage from "../pages/collectionPage";
import AddItemsToCollectionPage from "../pages/addItemsToCollectionPage";
import CollectionsPage from "../pages/CollectionsPage";
import CreateCollectionPage from "../pages/CreateCollectionPage";
import BlogPosts from "../pages/BlogPosts";
import Favorites from "../pages/Favorites";
import UserItemsPage from "../pages/UserItemsPage";
import Disclaimer from "../pages/Disclaimer";
import AdminSellers from "../pages/admin/AdminSellers";
import BlogPostList from "../pages/admin/BlogPostList";
import AdminCategoriesPage from "../pages/admin/AdminCategoriesPage";
import AdminCollectionsPage from "../pages/admin/AdminCollectionsPage";
import AdminItemsPage from "../pages/admin/AdminItemsPage";
import EditItem from "../pages/client/Item/EditItem";
import AdminAddItemsToCollectionPage from "../pages/admin/AdminAddItemsToColletionPage";
import AboutUs from "../pages/AboutUs";
import AdminDeletedItemsPage from "../pages/admin/AdminDeletedItemsPage";
import AdminUserHistory from "../pages/admin/AdminUserHistory";
import AdminMessagesPage from "../pages/admin/AdminMessagePage";
import AdminDashboard from "../pages/admin/AdminDashboard";
import PrivacyPolicyPage from "../pages/client/PrivacyPolicyPage";
export const ROUTES = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/items",
    component: Items,
  },
  {
    path: "/item/:id",
    component: SingleItem,
  },
  {
    path: "/sellers",
    component: Sellers,
  },
  {
    path: "/seller/:id",
    component: Seller,
  },

  {
    path: "/disclaimer",
    component: Disclaimer,
  },
  {
    path: "/contact-us",
    component: Contact,
  },
  {
    path: "/search",
    component: Search,
  },
  {
    path: "/collection/:id",
    component: CollectionPage,
  },
  {
    path: "/about-us",
    component: AboutUs,
  },

  {
    path: "/user-items/:id",
    component: UserItemsPage,
  },
  {
    path: "/collection/all/:id",
    component: CollectionsPage,
  },
  {
    path: "/favorites",
    component: Favorites,
  },
  {
    path: "/blog",
    component: BlogPosts,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicyPage,
  },
];

export const PROTECTED_ROUTES = [
  {
    path: "/account-visibility/edit",
    component: AccountVisiblity,
  },
  {
    path: "/password/edit",
    component: PasswordChange,
  },
  {
    path: "/profile/edit",
    component: EditProfile,
  },
  {
    path: "/featured/edit",
    component: FeaturedItems,
  },
  {
    path: "/hidden/all",
    component: Hiddens,
  },
  {
    path: "/hidden/collections",
    component: HiddenCollections,
  },
  {
    path: "/hidden/items",
    component: HiddenItems,
  },
  {
    path: "/collection/manage/:id",
    component: AddItemsToCollectionPage,
  },
  {
    path: "/item/single/new",
    component: NewItem,
  },
  {
    path: "/item/manage/:id",
    component: EditItem,
  },
  {
    path: "/:id/messages/",
    component: MessagesPage,
  },
  {
    path: "/collection/new",
    component: CreateCollectionPage,
  },
];
export const ADMIN_ROUTES = [
  {
    path: "/admin/users",
    component: AdminSellers,
  },
  {
    path: "/admin/blog",
    component: BlogPostList,
  },
  {
    path: "/admin/categories",
    component: AdminCategoriesPage,
  },
  {
    path: "/admin/collections",
    component: AdminCollectionsPage,
  },
  {
    path: "/admin/items",
    component: AdminItemsPage,
  },
  {
    path: "/admin/deleted-items/:id",
    component: AdminDeletedItemsPage,
  },
  {
    path: "/admin/item/manage/:id",
    component: EditItem,
  },
  {
    path: "/admin/collection/manage/:id",
    component: AdminAddItemsToCollectionPage,
  },
  {
    path: "/admin/user/messages/:id",
    component: AdminMessagesPage,
  },
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/admin/user/history/:id",
    component: AdminUserHistory,
  },
];
export const AUTH_ROUTES = [
  {
    path: "/auth/login",
    component: Login,
  },
  {
    path: "/auth/signup",
    component: Signup,
  },
  {
    path: "/auth/select-signup",
    component: SelectSignup,
  },
  {
    path: "/auth/forgot-password",
    component: Forgot,
  },
  {
    path: "/auth/reset-password/:token",
    component: ResetPassword,
  },
  {
    path: "/auth/verify/:token",
    component: Verify,
  },
];
